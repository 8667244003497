<xcrono-card title="Edição de senha do Atleta" [loading]="isLoadingResults">

  <div class="button-row">
    <a mat-flat-button color="primary" [routerLink]="['/atleta-list']"><mat-icon>list</mat-icon> Atleta</a>
  </div>
  
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 

    <mat-card class="example-card" *ngIf="!isLoadingResults">
      <mat-card-header>
          
        <mat-card-title-group>
          <mat-card-title><h2>{{atleta.email}}</h2></mat-card-title>
        </mat-card-title-group>
        
      </mat-card-header>
      <mat-card-content>

          <mat-card *ngIf="!isLoadingResults">
              <form [formGroup]="saveForm" (ngSubmit)="onFormSubmit()" class="form-container">
    
              <div fxLayout="row" fxLayoutGap="32px">
                
                <mat-form-field appearance="fill" fxFlex="40%">
                    <mat-label>Senha</mat-label>
                    <input type="password" matInput placeholder="Senha" formControlName="senha" autofocus required maxlength="45">
                    <mat-error>
                        <span *ngIf="!saveForm.get('senha').valid && saveForm.get('senha').touched">Informe a nova senha</span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="40%">
                  <mat-label>Confirmação</mat-label>
                  <input type="password" matInput placeholder="Confirmação da senha" formControlName="senha_confirmacao" autofocus required maxlength="45">
                  <mat-error>
                      <span *ngIf="!saveForm.get('senha_confirmacao').valid && saveForm.get('senha_confirmacao').touched">Informe a confirmação da senha</span>
                  </mat-error>
                </mat-form-field>

              </div>
              <div class="button-row">
                <button type="submit" [disabled]="!saveForm.valid"  mat-flat-button color="warn"><mat-icon>playlist_add</mat-icon>Editar</button>
              </div>
            </form>
          </mat-card>
      </mat-card-content>
    </mat-card>
  </div>
</xcrono-card>