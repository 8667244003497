<xcrono-card title="Inclusão de Categoria" [loading]="isLoadingResults">

  <div class="button-row">
    <a mat-flat-button color="primary" [routerLink]="['/category-list', evento.id]"><mat-icon>list</mat-icon> Categoria</a>
  </div>
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 

    <mat-card class="example-card" *ngIf="!isLoadingResults">
      <mat-card-header>
          
        <mat-card-title-group>
          <mat-card-title><h2>Dados da Categoria</h2></mat-card-title>
          <mat-card-subtitle>Informe todos os dados da categoria.</mat-card-subtitle>
        </mat-card-title-group>
        
      </mat-card-header>
      <mat-card-content>

          <mat-card *ngIf="!isLoadingResults">
              <form [formGroup]="saveForm" (ngSubmit)="onFormSubmit()" class="form-container">
    
              <div fxLayout="row" fxLayoutGap="32px">
                
                <mat-form-field appearance="fill" fxFlex="80%">
                    <mat-label>Nome</mat-label>
                    <input matInput placeholder="Nome" formControlName="nome" autofocus required maxlength="45">
                    <mat-error>
                        <span *ngIf="!saveForm.get('nome').valid && saveForm.get('nome').touched">Informe o nome da categoria</span>
                    </mat-error>
                </mat-form-field>

                <mat-radio-group fxFlex="20%" fxFlexAlign="center_" formControlName="sexo" fxLayout="column" fxLayoutAlign="start start">
                  <mat-radio-button value="M">Masculino</mat-radio-button>
                  <mat-radio-button value="F">Feminino</mat-radio-button>
                </mat-radio-group>

              </div>


              <div fxLayout="row" fxLayoutGap="32px">
                
                <mat-form-field appearance="fill" fxFlex="30%">
                    <mat-label>Código</mat-label>
                    <input matInput placeholder="Código" formControlName="codigo" required maxlength="45">
                    <mat-error>
                        <span *ngIf="!saveForm.get('percurso').valid && saveForm.get('percurso').touched">Informe o percurso da categoria</span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="30%">
                  <mat-label>Percurso</mat-label>
                  <input matInput placeholder="Percurso" formControlName="percurso" required maxlength="45">
                  <mat-error>
                      <span *ngIf="!saveForm.get('codigo').valid && saveForm.get('codigo').touched">Informe o código da categoria</span>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="20%">
                  <mat-label>Idade Início</mat-label>
                  <input matInput placeholder="Idade Início" formControlName="idade_inicio" min="0" max="110" maxlength="2" type="number">
                  <mat-error>
                      <span *ngIf="!saveForm.get('idade_inicio').valid && saveForm.get('idade_inicio').touched">Informe a idade de início para a categoria.</span>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="20%">
                  <mat-label>Idade Fim</mat-label>
                  <input matInput placeholder="Idade Fim" formControlName="idade_fim" min="0" max="110" maxlength="3" type="number">
                  <mat-error>
                      <span *ngIf="!saveForm.get('idade_fim').valid && saveForm.get('idade_fim').touched">Informe a idade limite para a categoria.</span>
                  </mat-error>
                </mat-form-field>

              </div>

              <div fxLayout="row" fxLayoutGap="32px">
                <mat-form-field  appearance="fill" fxFlex="40%">
                  <mat-label>Modalidade</mat-label>
                  <mat-select formControlName="modalidade_id">
                    <mat-option *ngFor="let modalidade of modalidades" [value]="modalidade.id">
                      {{modalidade.nome}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="button-row">
                <button type="submit" [disabled]="!saveForm.valid"  mat-flat-button color="warn"><mat-icon>playlist_add</mat-icon>Incluir</button>
              </div>
            </form>   
          </mat-card>  
      </mat-card-content>
    </mat-card>
  </div>




</xcrono-card>