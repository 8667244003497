<xcrono-card title="Ranking do Evento" [loading]="isLoadingResults">

  <div class="button-row">
    <a mat-flat-button color="primary" [routerLink]="['/event-list']"><mat-icon>list</mat-icon> Evento</a>
  </div>
  
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 

    <mat-card class="example-card" *ngIf="!isLoadingResults">
      <mat-card-header>
          
        <mat-card-title-group>
          <mat-card-title><h2>Classificação do evento</h2></mat-card-title>
          <mat-card-subtitle>A classificação está agrupada por categoria.</mat-card-subtitle>
        </mat-card-title-group>        
      </mat-card-header>
      <mat-card-content>

          <mat-card  *ngFor="let categoria of rankingEvento.categorias">

            <mat-card-title-group>
              <mat-card-title><h3 class="text-app-primary">[{{categoria.codigo}}] {{categoria.nome}}</h3></mat-card-title>              
            </mat-card-title-group> 
            <mat-card-content>
              <mat-list>
                <mat-divider></mat-divider>
                <div mat-subheader>Classificados</div>
                <mat-list-item>
                  <mat-icon mat-list-icon>alarm_on</mat-icon>
                  <div mat-line *ngFor="let inscricao of categoria.inscricoes">[{{inscricao.tempo}}] {{inscricao.inscricao_nome}}</div>                
                </mat-list-item>
                <mat-divider></mat-divider>
                <div mat-subheader *ngIf="categoria.inscricoesDesclassificadas">Desclassificados</div>
                <mat-list-item *ngIf="categoria.inscricoesDesclassificadas">
                  <mat-icon mat-list-icon>alarm_off</mat-icon>
                  <div mat-line *ngFor="let inscricao of categoria.inscricoesDesclassificadas">{{inscricao.inscricao_nome}}</div>
                </mat-list-item>
              </mat-list>
            </mat-card-content>

          </mat-card>

      </mat-card-content>
    </mat-card>
  </div>
</xcrono-card>