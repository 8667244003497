import { Component, OnInit } from '@angular/core';
import { Modality } from 'src/app/model';
import { ApiService } from 'src/app/shared';

@Component({
  selector: 'app-modality-list',
  templateUrl: './modality-list.component.html',
  styleUrls: ['./modality-list.component.scss']
})
export class ModalityListComponent implements OnInit {

  modalidades: Array<Modality> = [];
  displayedColumns: string[] = ['nome'];
  isLoadingResults: boolean = false;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.loadList();
  }

  private loadList(): void {
    this.isLoadingResults = true;
    this.apiService.listModality()
    .subscribe((res: Array<Modality>) => {
      this.modalidades = res;
      this.isLoadingResults = false;
    }, err => {
      console.log(err);
      this.apiService.openSnackBarError(err.error.message);
      this.isLoadingResults = false;
    });
  }

}
