import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Category, ModelFactory } from 'src/app/model';
import { ApiService } from 'src/app/shared';

@Component({
  selector: 'app-category-del',
  templateUrl: './category-del.component.html',
  styleUrls: ['./category-del.component.scss']
})
export class CategoryDelComponent implements OnInit {

  isLoadingResults: boolean = false;
  categoria: Category = ModelFactory.createCategoryEmpty();
  evento_id: number = null;

  constructor(private route: ActivatedRoute, private apiService: ApiService, 
    private router: Router) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.getDetail(this.route.snapshot.params.id);
  }


  getDetail(id: number) {
    this.getCategoria(id);
  }

  getCategoria(id: number) {
    this.apiService.getCategory(id)
      .subscribe((data: Category) => {
        this.categoria = data;
        this.evento_id = this.categoria.evento_id;
        this.isLoadingResults = false;
      });    
  }


  removerCategoria() {
    this.apiService.deleteCategory(this.categoria.id)
      .subscribe((data: Category) => {
        this.isLoadingResults = false;
        this.apiService.openSnackBarSuccess(`A categoria ${this.categoria.nome} foi removida.`);
        this.router.navigate(['/category-list', this.evento_id])
      }, err => {
        this.apiService.openSnackBarError(err.error.message);
        this.isLoadingResults = false;
      });   
  }


}
