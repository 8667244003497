import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatTreeModule } from '@angular/material/tree';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';

import { LoginComponent, LogoutComponent, HomeComponent, PageNotFoundComponent } from './';
import { EventListComponent, EventNewComponent, EventEditComponent, EventDetailComponent, EventImportComponent, 
  EventNextStatusComponent, EventRateComponent, EventBannerComponent, EventRankingComponent } from './';
import { CategoryListComponent, CategoryDetailComponent, CategoryEditComponent, CategoryNewComponent, CategoryDelComponent } from './';
import { ModalityListComponent, ModalityDetailComponent, ModalityNewComponent, ModalityEditComponent } from './';
import { InscricaoEditComponent } from './';
import { AtletaListComponent, AtletaSenhaComponent } from './';

import { SharedModule } from '../shared/shared.module';

import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    HomeComponent,
    PageNotFoundComponent,
    EventListComponent,
    EventDetailComponent,
    EventEditComponent,
    EventNewComponent,
    EventImportComponent,
    EventNextStatusComponent,
    EventRateComponent,
    EventBannerComponent,
    CategoryListComponent,
    CategoryDetailComponent,
    CategoryEditComponent,
    CategoryNewComponent,
    CategoryDelComponent,
    InscricaoEditComponent,
    AtletaListComponent,
    ModalityListComponent,
    ModalityDetailComponent,
    ModalityNewComponent,
    ModalityEditComponent,
    AtletaSenhaComponent,
    EventRankingComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    SharedModule,

    FlexLayoutModule,

    MatToolbarModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatProgressBarModule,
    MatListModule,
    MatTreeModule,
    MatExpansionModule,
    MatTabsModule,
    MatSnackBarModule,
    MatDialogModule,
    ScrollingModule,
    MatRadioModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
  ],
  exports: [
    LoginComponent,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
  ]
})
export class ViewModule {}
