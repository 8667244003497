<h1 mat-dialog-title>{{data.inscricao.inscricao_nome}} </h1>
<h4> Numero de Peito: {{data.inscricao.numero_peito}}</h4>

<div mat-dialog-content>
  <h4> Numero de Peito: {{data.evento.categorias[0].nome}}</h4>
<ng-container *ngIf="data.evento.categorias">
  
  
</ng-container>

</div>

<div md-dialog-content>
  <div **ngFor="let opt of data.evento.categorias">{{opt.nome}}</div>
  Mycontent
</div>



<!--
  <mat-select placeholder="Which option?" >
  
    <mat-option value="1">Option 1</mat-option>
    <mat-option value="2" >Option 2 (disabled)</mat-option>
    <mat-option value="3">Option 3</mat-option>
  <mat-option *ngFor="let opt of data.evento.categorias" [value]="opt.id">{{opt.nome}}</mat-option>
</mat-select>
-->

<select matNativeControl >
  
  <option value="" selected></option>
  <option value="volvo">Volvo</option>
  <option value="saab" disabled>Saab</option>
  <option value="mercedes">Mercedes</option>
  <option value="audi">Audi</option>
</select>

<!--
    <h3 *ngFor="let categoria of evento.categorias">
      {{categoria.nome}}
    </h3>
    <mat-form-field>
      <mat-label>What's your name?</mat-label>
      <input matInput [(ngModel)]="data.inscricao.inscricao_nome">
    </mat-form-field>


  <mat-form-field appearance="fill">
  <mat-label>Categoria</mat-label>
  <mat-select [(value)]="data.categoria" [compareWith]="compareCategoriaEvent">
    <mat-option *ngFor="let categoria of data.evento.categorias" [value]="categoria">
      {{categoria.nome}}
    </mat-option>
  </mat-select>
</mat-form-field>
-->
<div mat-dialog-actions>
  <button mat-flat-button (click)="onNoClick()">Cancelar</button>
  <!--
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
  -->
</div>