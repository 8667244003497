import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Event, ImportacaoInscricao, ModelFactory } from 'src/app/model';
import { ApiService } from 'src/app/shared';

@Component({
  selector: 'app-event-import',
  templateUrl: './event-import.component.html',
  styleUrls: ['./event-import.component.scss']
})
export class EventImportComponent implements OnInit {

  isLoadingResults: boolean = false;
  nomeArquivo: string = '';
  file: File;
  importacao: ImportacaoInscricao;

  evento: Event = ModelFactory.createEventEmpty();

    constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.nomeArquivo = null;
    this.getDetail(this.route.snapshot.params.id);
  }


  getDetail(id: number) {
    this.getEvent(id);
  }

  getEvent(id: number) {
    this.apiService.getEvent(id)
      .subscribe((data: Event) => {
        this.evento = data;
        this.isLoadingResults = false;
      }, (err: any) => {
        console.log(err);
        this.apiService.openSnackBarError(err.error.message);
        this.isLoadingResults = false;
      });    
  }


  

 

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.file = file;
      this.nomeArquivo = file.name;
      //this.uploadForm.get('profile').setValue(file);
    }
  }


  upload(){
    this.isLoadingResults = true;
    this.apiService.uploadInscricao(this.file, this.evento)
    .subscribe((res: ImportacaoInscricao) => {
      console.log(res);
      this.importacao = res;
      this.isLoadingResults = false;
      if(this.importacao.sucesso){
        this.apiService.openSnackBarSuccess(`O processo importou ${this.importacao.totalInscricoes} inscrições.`);
      }else{
        this.apiService.openSnackBarInfo(`O processo leu ${this.importacao.totalInscricoes} inscrições, mas não conseguiu salvar os registros`);
      }
      this.router.navigate(['/event-detail', this.evento.id]);
    }, err => {
      console.log(err);
      this.isLoadingResults = false;
      this.apiService.openSnackBarError(err.error.message);
    });
    
  }




}
