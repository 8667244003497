<xcrono-card title="Avaliação de atividade da Inscrição" [loading]="isLoadingResults">

  <div class="button-row" *ngIf="!isLoadingResults">
    <a mat-flat-button color="primary" [routerLink]="['/event-rate', inscricao.evento_id]"><mat-icon>arrow_back</mat-icon> Evento</a>
  </div>
  
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 

    <mat-card class="example-card">
      <mat-card-header>          
        <mat-card-title-group>
          <mat-card-title><h2> Número de Peito {{inscricao.numero_peito}} - {{inscricao.inscricao_nome}}</h2></mat-card-title>
          <mat-card-subtitle>{{inscricao.email}}</mat-card-subtitle>
        </mat-card-title-group>        
      </mat-card-header>
      <mat-card-content>

        <mat-list >
          <div mat-subheader>{{inscricao.modalidade_nome}} [{{inscricao.percurso}} / {{inscricao.categoria_sexo | genero}} / {{inscricao.idade_inicio}} até {{inscricao.idade_fim}} anos] - {{inscricao.categoria_nome}} - {{inscricao.categoria_codigo}}</div>
          <mat-divider></mat-divider>
          <mat-list-item>
            <mat-icon mat-list-icon>face</mat-icon>
            <div mat-line> CPF: {{inscricao.cpf}} </div>
            <div mat-line> Idade: {{inscricao.idade}} [{{inscricao.data_nascimento | date : 'dd/MM/yyyy'}}] </div>
            <div mat-line> Sexo: {{inscricao.sexo | genero}} </div>
            <div mat-line> Celular: {{inscricao.celular}} </div>
            <div mat-line> Equipe: {{inscricao.equipe}} </div>
            <mat-divider></mat-divider>
            <!--
              <div mat-line>
                <button mat-flat-button matTooltip="Click para avaliar" [disabled]="!inscricao.atividades" color="accent" [routerLink]="['/inscricao-edit', inscricao.inscricao_id]">
                  <mat-icon>rule</mat-icon>
                  <span>Avaliar</span>
                </button>
              </div>
            -->

          </mat-list-item>
        </mat-list>


        <span *ngIf="atividades">

          <h3>Atividades</h3>

          <mat-accordion *ngFor="let atividade of atividades; let i = index">
            <mat-expansion-panel [expanded]="stepAtividade === i" (opened)="setStepAtividade(i)" hideToggle>
                <mat-expansion-panel-header fxLayout="row" [ngClass]="getClass(atividade.status.codigo)">
                  <mat-panel-title> [{{atividade.status.descricao}}] {{atividade.data_envio | date : 'dd/MM/yyyy - H:mm:ss'}}</mat-panel-title>
                  <mat-panel-description dir="rtl"> Tempo {{atividade.tempo}} <mat-icon>alarm</mat-icon></mat-panel-description>
                  <mat-icon>more_vert</mat-icon>
                </mat-expansion-panel-header>


                <mat-card fxLayout="column" class="mat-elevation-z0">
                  <mat-card-header>
                    <mat-card-title>{{atividade.tempo}} </mat-card-title>
                    <mat-card-subtitle>Enviado em {{atividade.data_envio | date : 'dd/MM/yyyy - H:mm:ss'}}</mat-card-subtitle>
                    <mat-card-subtitle>Revisado em {{atividade.data_revisao | date : 'dd/MM/yyyy - H:mm:ss'}}</mat-card-subtitle>
                  </mat-card-header>

                  <mat-card-content *ngIf="atividade.justificativa">
                    <p>Justificativa:</p>
                    <p>{{atividade.justificativa}}</p>
                  </mat-card-content>
                  <mat-card>
                      <mat-card-content>
                        <div fxLayout="row" fxLayoutAlign="space-around center" >
                          <img mat-card-image src="{{'data:image/jpg;base64,' + imagem.imagem}}" alt="{{imagem.nome}}" *ngFor="let imagem of atividade?.imagens; let i = index">                  
                        </div>
                      </mat-card-content>
                  </mat-card>
                  
                  

                  <mat-form-field fxFlex="100%" class="justificativa">
                    <mat-label>Justificativa<strong></strong></mat-label>
                    <textarea matInput name="justificativa" rows="12" [(ngModel)]="justificativa" appearance="fill" placeholder="Digite a justificativa para a mudança de status">
                    </textarea>
                  </mat-form-field>

                  <mat-card-actions fxLayout="row" fxLayoutAlign="space-around center">
                    <button mat-flat-button color="primary" (click)="registrarAprovacao(atividade.id)">
                      <mat-icon>rule</mat-icon>
                      <span>Aprovar</span>
                    </button>
                    <button mat-flat-button color="warn" (click)="registrarRejeicao(atividade.id)">
                      <mat-icon>rule</mat-icon>
                      <span>Rejeitar</span>
                    </button>
                    <button mat-flat-button color="accent" (click)="registrarDesclassificacao(atividade.id)">
                      <mat-icon>rule</mat-icon>
                      <span>Desclassificar</span>
                    </button>
                  </mat-card-actions>            
      
                </mat-card>

            </mat-expansion-panel>    
          </mat-accordion>      



        </span>


      </mat-card-content>
    </mat-card>
  </div>
</xcrono-card>