import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Category, Event, InscricaoView, ModelFactory } from 'src/app/model';
import { ApiService } from 'src/app/shared';
import { CategoryDetailComponent } from '../../category/category-detail/category-detail.component';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {

  isLoadingResults: boolean = false;
  evento: Event = ModelFactory.createEventEmpty();

  constructor(private route: ActivatedRoute, private apiService: ApiService, 
    private router: Router, private formBuilder: FormBuilder, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.getDetail(this.route.snapshot.params.id);
  }


  getDetail(id: number) {
    this.getEvent(id);
  }

  getEvent(id: number) {
    this.apiService.getEvent(id)
      .subscribe((data: Event) => {
        this.evento = data;
        this.isLoadingResults = false;
      });    
  }


  step = -1;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }



  stepInscricao = -1;

  setStepInscricao(index: number) {
    this.stepInscricao = index;
  }

  nextStepInscricao() {
    this.stepInscricao++;
  }

  prevStepInscricao() {
    this.stepInscricao--;
  }


  openDialog(inscricao: InscricaoView, eventoAtual: Event): void {

    const categ = ModelFactory.createCategorySimple(inscricao.categoria_id, inscricao.categoria_nome, inscricao.categoria_codigo);

    const dialogRef = this.dialog.open(DialogMudarCategoria, {
      width: '60%',
      data: {evento: eventoAtual, inscricao: inscricao, categoria: categ, selected:categ.id}
    });

    dialogRef.afterClosed().subscribe(inscricao => {
      console.log('The dialog was closed');
      //this.inscricao = inscricao;
    });
  }


}



export interface DialogData {
  evento: Event;
  inscricao: InscricaoView;
  categoria: Category;
  selected: number;
}

@Component({
  selector: 'dialog-mudar-categoria',
  templateUrl: 'dialog-mudar-categoria.html',
})
export class DialogMudarCategoria {

  selected: number = 1;

  constructor(
    public dialogRef: MatDialogRef<DialogMudarCategoria>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  compareCategoriaEvent(o1: Category, o2: Category): boolean {
    return o1.id === o2.id;
  }

}
