export * from './login/login.component';
export * from './logout/logout.component';
export * from './home/home.component';
export * from './page-not-found/page-not-found.component';

export * from './event/event-list/event-list.component';
export * from './event/event-new/event-new.component';
export * from './event/event-edit/event-edit.component';
export * from './event/event-detail/event-detail.component';
export * from './event/event-import/event-import.component';
export * from './event/event-next-status/event-next-status.component';
export * from './event/event-rate/event-rate.component';
export * from './event/event-banner/event-banner.component';
export * from './event/event-ranking/event-ranking.component';

export * from './category/category-list/category-list.component';
export * from './category/category-new/category-new.component';
export * from './category/category-edit/category-edit.component';
export * from './category/category-detail/category-detail.component';
export * from './category/category-del/category-del.component';

export * from './modality/modality-list/modality-list.component';
export * from './modality/modality-new/modality-new.component';
export * from './modality/modality-edit/modality-edit.component';
export * from './modality/modality-detail/modality-detail.component';

export * from './inscricao/inscricao-edit/inscricao-edit.component';

export * from './atleta/atleta-list/atleta-list.component';
export * from './atleta/atleta-senha/atleta-senha.component';