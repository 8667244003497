<xcrono-card title="Importar Inscrições do Evento" [loading]="isLoadingResults">

  <div class="button-row">
    <a mat-flat-button color="primary" [routerLink]="['/event-list']"><mat-icon>list</mat-icon> Evento</a>
  </div>
  
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 

    <mat-card class="example-card" *ngIf="!isLoadingResults">
      <mat-card-header>
          
        <mat-card-title-group>
          <mat-card-title><h2>Evento{{evento.evento_virtual ? ' Virtual' : ''}}: {{evento.nome}} </h2></mat-card-title>
          <mat-card-subtitle>Após a seleção do arquivo a importação será iniciada automaticamente.</mat-card-subtitle>
          <mat-card-subtitle>Aplanilha deve conter as colunas com as descrições a seguir: <strong>Nome, E-mail, CPF, Data de Nascimento, Sexo, Percurso, Número do peito, Telefone de contato, Equipe e Código</strong>.</mat-card-subtitle>
        </mat-card-title-group>
        
      </mat-card-header>
      <mat-card-content>
          <mat-card *ngIf="!isLoadingResults">

            <div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="space-around stretch">
              <mat-form-field appearance="outline" (click)="imgFileInput.click()" fxFlex="80%">
                <mat-label>Arquivo de inscrições</mat-label>
                <input matInput placeholder="Selecione um arquivo" [value]="nomeArquivo" readonly>
                <input hidden type="file" #imgFileInput (change)="onFileSelect($event)"/>
                <mat-icon matSuffix>attach_file</mat-icon>
                <mat-hint>Arquivo</mat-hint>
              </mat-form-field>
              <div fxFlex="20%"></div>
            </div>
            <div class="button-row">
              <button type="button" mat-flat-button color="warn" (click)="upload()" [disabled]="!file"><mat-icon>cloud_upload</mat-icon> &nbsp; Enviar</button>
            </div>

          </mat-card>
        </mat-card-content>
    </mat-card>
    
  </div>
          
</xcrono-card>