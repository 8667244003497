<xcrono-card title="Remover categoria" [loading]="isLoadingResults">

  <div class="button-row" *ngIf="!isLoadingResults">
    <a mat-flat-button color="primary" [routerLink]="['/category-list', evento_id]"><mat-icon>list</mat-icon> Categoria</a>
    <a mat-flat-button color="accent" [routerLink]="['/category-edit', categoria.id]"><mat-icon>add</mat-icon> Editar Categoria </a>
  </div>
  
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 

    <mat-card class="example-card">
      <mat-card-header>          
        <mat-card-title-group>
          <mat-card-title><h2>Deseja remover a categoria {{categoria.nome}} ?</h2></mat-card-title>
          <mat-card-subtitle>Após a confirmação a operação não poderá ser desfeita.</mat-card-subtitle>
        </mat-card-title-group>        
      </mat-card-header>
      <mat-card-content>
        <div class="button-row">
          <button type="button" mat-flat-button color="accent" (click)="removerCategoria()"><mat-icon>clear</mat-icon> &nbsp; Remover</button>
        </div>
      </mat-card-content>
    </mat-card>

  </div>
</xcrono-card>