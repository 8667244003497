import { Event, Category, Modality, ImportacaoInscricao, InscricaoView, StatusAtividade } from '.';

export class ModelFactory {

  /* Status evento */
  public static get RASCUNHO(): number {return 1};
	public static get INSCRICAO(): number {return 2};
  public static get EM_ANDAMENTO(): number {return 3};
  public static get APURACAO(): number {return 4};
  public static get FINALIZADO(): number {return 5};
  

  /* Status das atividades da inscrição */
  public static get INSCRICAO_SEM_ATIVIDADE(): number {return 1};
  public static get INSCRICAO_ATIVIDADE_REGISTRADA(): number {return 2};
  public static get INSCRICAO_ATIVIDADE_ACEITA(): number {return 3};
  public static get INSCRICAO_ATIVIDADE_RECUSADA(): number {return 4};

  /* Status atividade */
  public static get ATIVIDADE_RECEBIDA(): number {return 1};
  public static get ATIVIDADE_APROVADA(): number {return 2};
  public static get ATIVIDADE_REJEITADA(): number {return 3};
  public static get ATIVIDADE_DESCLASSIFICADO(): number {return 4};

  static statusAtividadeRecebida(): StatusAtividade{
    return {codigo: `${this.ATIVIDADE_RECEBIDA}`, descricao:"Recebida" };
  } 
  static statusAtividadeAprovada(): StatusAtividade{
    return {codigo:`${this.ATIVIDADE_APROVADA}`, descricao:"Aprovada" };
  }
  static statusAtividadeRejeitada(): StatusAtividade{
    return {codigo:`${this.ATIVIDADE_REJEITADA}`, descricao:"Rejeitada" };
  }
  static statusAtividadeDesclassificada(): StatusAtividade{
    return {codigo:`${this.ATIVIDADE_DESCLASSIFICADO}`, descricao:"Desclassificado" };
  }

  static createCategoryEmpty(): Category{
    return this.createCategory(null, null, null, null, null, null, null, null, null);
  }

    
  static createCategory(id: number, nome: string, codigo: string, idadeInicio: number,
    idadeFim: number, sexo: string, percurso: string, modalidade: Modality, evento_id: number): Category{
    return {id: id,
              nome: nome,
              codigo: codigo,
              idadeInicio: idadeInicio,
              idadeFim: idadeFim,
              sexo: sexo,
              percurso: percurso,
              modalidade: modalidade,
              evento_id: evento_id,
              modalidade_id: modalidade?.id
            };
  }

  static createCategorySimple(id: number, nome: string, codigo: string): Category{
    return this.createCategory(id, nome, codigo, null, null, null, null, null, null);
  }

    static createEventEmpty(): Event{
      return this.createEvent(null, null, null, null, null, null, null, null, null, null, true, null, null, null);
    }

    static createEvent(id: number, nome: string, descricao: string, inicio: string, fim: string, 
      status: string, inicio_envio: string, fim_envio: string, cidade: string, 
      quantidade_imagem:number, virtual: boolean, categorias: Category[], importacoes: ImportacaoInscricao[], inscricoes: InscricaoView[]): Event{
      return { 
        id: id,
        nome: nome,
        descricao: descricao,
        data_inicio: inicio,
        data_fim: fim,
        status: {codigo: '1', descricao: status},
        data_inicio_envio: inicio_envio,
        data_fim_envio: fim_envio,
        evento_virtual: virtual,
        cidade: cidade,
        quantidade_imagem: quantidade_imagem,
        categorias: categorias,
        importacoes: importacoes,
        inscricoes: inscricoes,
      };
    }
    
  }