<xcrono-card title="Avaliação de atividades do Evento" [loading]="isLoadingResults">

  <div class="button-row">
    <a mat-flat-button color="primary" [routerLink]="['/event-list']"><mat-icon>list</mat-icon> Evento</a>
  </div>
  
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 

    <mat-card *ngIf="!isLoadingResults">
      <mat-card-header>
          
        <mat-card-title-group>
          <mat-card-title><h2>Evento{{evento.evento_virtual ? ' Virtual' : ''}}: {{evento.nome}} </h2></mat-card-title>
            <mat-card-subtitle>{{evento.descricao}}.</mat-card-subtitle>
        </mat-card-title-group>
        
      </mat-card-header>
      <mat-card-content>

        <mat-card class="card-data">
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="10px">
            <div fxFlexOffset="8" fxFlex="15%">Data início:</div>
            <div fxFlexOffset="0" fxFlex="45%">{{evento.data_inicio | date:'dd/MM/yyyy'}}</div>
            <div fxFlexOffset="0" fxFlex="15%">Data fim:</div>
            <div fxFlexOffset="0" fxFlex="25%">{{evento.data_fim | date:'dd/MM/yyyy'}}</div>
          </div>
        </mat-card>
        <mat-card class="card-data">
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="10px">
            <div fxFlexOffset="8" fxFlex="15%">Data início envio:</div>
            <div fxFlexOffset="0" fxFlex="45%">{{evento.data_inicio_envio | date:'dd/MM/yyyy'}}</div>
            <div fxFlexOffset="0" fxFlex="15%">Data fim envio:</div>
            <div fxFlexOffset="0" fxFlex="25%">{{evento.data_fim_envio | date:'dd/MM/yyyy'}}</div>
          </div>
        </mat-card>


           <!-- INSCRICOES -->
           
            <mat-accordion *ngFor="let inscricao of evento.inscricoes let i = index">
              <mat-expansion-panel [expanded]="stepInscricao === i" (opened)="setStepInscricao(i)" hideToggle>
                  <mat-expansion-panel-header fxLayout="row" 
                  [ngClass]="getClass(inscricao.status.codigo)">
                    <mat-panel-title>
    
                      <span *ngIf="inscricao.status.codigo === INSCRICAO_ATIVIDADE_REGISTRADA">[Recebido] [{{inscricao.inscricao_id}}]  {{inscricao.inscricao_nome}}</span>
                      <span *ngIf="inscricao.status.codigo === INSCRICAO_ATIVIDADE_ACEITA || inscricao.status.codigo === INSCRICAO_ATIVIDADE_RECUSADA">[Avaliado] [{{inscricao.inscricao_id}}]  {{inscricao.inscricao_nome}}</span>
                      <span *ngIf="inscricao.status.codigo === INSCRICAO_SEM_ATIVIDADE">[Pendente] [{{inscricao.inscricao_id}}]  {{inscricao.inscricao_nome}}</span>
                      
                    </mat-panel-title>
                    <mat-panel-description dir="rtl">Número de Peito {{inscricao.numero_peito}} <mat-icon>account_circle</mat-icon></mat-panel-description>
                    <mat-icon>more_vert</mat-icon>
                  </mat-expansion-panel-header>
    
                  <mat-list >
                    <div mat-subheader>{{inscricao.modalidade_nome}} [{{inscricao.percurso}} / {{inscricao.categoria_sexo | genero}} / {{inscricao.idade_inicio}} até {{inscricao.idade_fim}} anos] - {{inscricao.categoria_nome}} - {{inscricao.categoria_codigo}}</div>
                    <mat-divider></mat-divider>
                    <mat-list-item>
                      <mat-icon mat-list-icon>face</mat-icon>
                      <div mat-line> E-mail: {{inscricao.email}} </div>
                      <div mat-line> CPF: {{inscricao.cpf}} </div>
                      <div mat-line> Idade: {{inscricao.idade}} [{{inscricao.data_nascimento | date : 'dd/MM/yyyy'}}] </div>
                      <div mat-line> Sexo: {{inscricao.sexo | genero}} </div>
                      <div mat-line> Celular: {{inscricao.celular}} </div>
                      <div mat-line> Equipe: {{inscricao.equipe}} </div>
                      <div mat-line> Percurso: {{inscricao.percurso}} </div>
                      <mat-divider></mat-divider>
                      <div mat-line>
                        <!--
                          <a mat-flat-button color="accent" (click)="openDialog(inscricao, evento)"><mat-icon>settings</mat-icon> Mudar categoria </a>
                        -->
                        <button mat-flat-button matTooltip="Click para avaliar" [disabled]="!inscricao.atividades" color="accent" [routerLink]="['/inscricao-edit', inscricao.inscricao_id]">
                          <mat-icon>rule</mat-icon>
                          <span>Avaliar</span>
                        </button>
                      </div>

                    </mat-list-item>
                  </mat-list>


                </mat-expansion-panel>
            </mat-accordion>    
          

        

      </mat-card-content>
    </mat-card>
  </div>
</xcrono-card>