import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelFactory, Event } from 'src/app/model';
import { ApiService } from 'src/app/shared';

@Component({
  selector: 'app-event-rate',
  templateUrl: './event-rate.component.html',
  styleUrls: ['./event-rate.component.scss']
})
export class EventRateComponent implements OnInit {

  public INSCRICAO_ATIVIDADE_ACEITA: number = ModelFactory.INSCRICAO_ATIVIDADE_ACEITA;
	public INSCRICAO_ATIVIDADE_REGISTRADA: number = ModelFactory.INSCRICAO_ATIVIDADE_REGISTRADA;
  public INSCRICAO_SEM_ATIVIDADE: number = ModelFactory.INSCRICAO_SEM_ATIVIDADE;
  public INSCRICAO_ATIVIDADE_RECUSADA: number = ModelFactory.INSCRICAO_ATIVIDADE_RECUSADA;
	

  isLoadingResults: boolean = false;
  evento: Event = ModelFactory.createEventEmpty();
  
  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.getEvent(this.route.snapshot.params.id);
  }


  getEvent(id: number) {
    this.apiService.getEventRate(id)
      .subscribe((data: Event) => {
        this.evento = data;
        //console.log(JSON.stringify(data));        
        this.isLoadingResults = false;
      }, (err: any) => {
        console.log(err);
        this.apiService.openSnackBarError(err.error.message);
        this.isLoadingResults = false;
      });
  }

  getClass(status: number){
    return (status === this.INSCRICAO_ATIVIDADE_ACEITA) ? 'bg-app-success' : 
           (status === this.INSCRICAO_ATIVIDADE_REGISTRADA) ? 'bg-app-primary' : 
           (status === this.INSCRICAO_ATIVIDADE_RECUSADA) ? 'bg-app-accent' : 
           (status === this.INSCRICAO_SEM_ATIVIDADE) ? 'bg-app-secondary' : '';
  }


  stepInscricao = -1;

  setStepInscricao(index: number) {
    this.stepInscricao = index;
  }

  nextStepInscricao() {
    this.stepInscricao++;
  }

  prevStepInscricao() {
    this.stepInscricao--;
  }

}
