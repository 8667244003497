import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { User, Event, Category, StatusEvent, LoginRequest, TokenJWT, ImportacaoInscricao, Atleta, InscricaoView, Atividade, AvaliacaoAtividade, Modality, Imagem, MudarSenhaRequest, EventRanking } from 'src/app/model';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { MockData } from './MockData';
import { MatSnackBar } from '@angular/material/snack-bar';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
const httpAuthOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'client_id':'xcrono-app', 
    'Authorization':'Basic eGNyb25vLWFwcDpteS1zZWNyZXQta2V5',
  })
};
const httpMultipartOptions = {
  headers: new HttpHeaders({'Content-Type': 'multipart/data-form'})
};
const httpPDFOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/pdf',
    Accept : 'application/pdf'
  }),
  responseType : 'blob' as 'json',
};
@Injectable({
  providedIn: 'root'
})
export class ApiService {
 

  private MOCK : MockData  = new MockData();
  private API_PROXY = environment.PATH_PROXY;  

  constructor(private http: HttpClient, private _snackBar: MatSnackBar) { }

  openSnackBar(message: string, action: string, className: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      panelClass: [className],
    });
  } 
  
  openSnackBarInfo(message: string) {
    this.openSnackBar(message, 'INFO', 'blue-snackbar');
  }
  
  openSnackBarSuccess(message: string) {
    this.openSnackBar(message, 'OK', 'green-snackbar');
  }
  
  openSnackBarError(message: string) {
    this.openSnackBar(message, 'ERRO', 'red-snackbar');
  }

  //TODO - método mock
  login(loginRequest: LoginRequest): Observable<TokenJWT> {
    return this.http.post<TokenJWT>(this.API_PROXY + 'oauth/token', this.getAuthFormData(loginRequest).toString(), httpAuthOptions);
    //return this.MOCK.getUser(user);    
  }

  getAuthFormData(loginRequest: LoginRequest):string {
    let formData = new URLSearchParams();
    formData.set("grant_type", loginRequest.grant_type);
    formData.set("username", loginRequest.username);
    formData.set("password", loginRequest.password);
    return formData.toString();
  }







  private uploadSingleFile(host: string, file: File): Observable<HttpEvent<{}>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    console.log(formData);
    return this.http.post<any>(
      host,
      formData,
      {
        reportProgress: true,
        observe: 'events'
      });
  }

  uploadInscricao(file: File, evento: Event): Observable<ImportacaoInscricao> {
    const formData: FormData = new FormData();
    formData.append('arquivo', file);
    formData.append('evento_id', `${evento.id}`);
    return this.http.post<ImportacaoInscricao>( this.API_PROXY + 'private/v1/inscricao/importar', formData);
  }



  uploadBanner(file: File, evento: Event): Observable<Event> {
    const formData: FormData = new FormData();
    formData.append('arquivo', file);
    formData.append('evento', `${evento.id}`);
    return this.http.post<Event>( this.API_PROXY + 'private/v1/evento/banner', formData);
  }

  saveEvent(evento: Event): Observable<Event> {
    return this.http.post<Event>( this.API_PROXY + 'private/v1/evento', evento, httpOptions);
  }
  getEvent(id: number){
    return this.http.get<Event>( this.API_PROXY + 'private/v1/evento/'+id );
  }
  getEventRate(id: number) {
    return this.http.get<Event>( this.API_PROXY + `private/v1/evento/${id}/avaliacao` );
  }
  delEvent(id: number){
    return this.http.delete<Event>( this.API_PROXY + 'private/v1/evento/'+id );
  }  
  updateEvent(configuracao: Event): Observable<Event> {
    console.log(configuracao);
    return this.http.put<Event>( this.API_PROXY + 'private/v1/evento', configuracao, httpOptions);
  }

  nextStatusEvent(eventoId: number): Observable<Event> {
    return this.http.put<Event>( this.API_PROXY + `private/v1/evento/${eventoId}/status`, httpOptions);
  }

  getInscricaoAvaliacao(id: number){
    return this.http.get<InscricaoView>( this.API_PROXY + `private/v1/inscricao/${id}/avaliacao` );
  }

  getAtividadesInscricao(id: number){
    return this.http.get<Array<Atividade>>( this.API_PROXY + `private/v1/inscricao/${id}/atividade` );
  }

  avaliarAtividade(avaliacao: AvaliacaoAtividade): Observable<Atividade> {
    return this.http.put<Atividade>( this.API_PROXY + 'private/v1/inscricao/atividade/avaliacao', avaliacao, httpOptions);
  }

  getEventRanking(id: number){
    return this.http.get<EventRanking>( this.API_PROXY + 'private/v1/evento/ranking/'+id );
  }

  getEventRankingPDF(id: number){
    return this.http.get<any>( this.API_PROXY + 'private/v1/evento/ranking/pdf/'+id, httpPDFOptions );
  }

  getEventRankingGeralPDF(id: number){
    return this.http.get<any>( this.API_PROXY + 'private/v1/evento/ranking/geral/pdf/'+id, httpPDFOptions );
  }


  //TODO - método mock
  listEvent(): Observable<Array<Event>>{
    return this.http.get<Array<Event>>( this.API_PROXY + 'private/v1/evento' );
    //return this.MOCK.getEvents();
  }

  listStatusEvent(): Observable<Array<StatusEvent>>{
    return this.http.get<Array<StatusEvent>>( this.API_PROXY + 'private/v1/status-evento' );
  }

  listBanners(id: number): Observable<Array<Imagem>>{
    return this.http.get<Array<Imagem>>( `api/private/v1/evento/${id}/banner/` );
  }

  filterEvent(evento: Event): Observable<Event>{
    return this.http.post<Event>( this.API_PROXY + 'private/v1/filtrar-evento', evento, httpOptions );
    //return this.MOCK.getEvents();
  }
  

  /* Category */
  listCategories(id: number): Observable<Array<Category>>{
    return this.http.get<Array<Category>>( 'api/private/v1/categoria/evento/' + id  );
  }

  saveCategorY(category: Category): Observable<Category> {
    return this.http.post<Category>( this.API_PROXY + 'private/v1/categoria', category, httpOptions);
  }

  getCategory(id: number){
    return this.http.get<Category>( this.API_PROXY + 'private/v1/categoria/'+id );
  }

  deleteCategory(id: number){
    return this.http.delete<Category>( this.API_PROXY + 'private/v1/categoria/'+id );
  }


  /* Atletas */
  listAtletas(): Observable<Array<Atleta>>{
    return this.http.get<Array<Atleta>>(this.API_PROXY + 'private/v1/atleta' );
  }

  getAtleta(id: number){
    return this.http.get<Atleta>( this.API_PROXY + 'private/v1/usuario/'+id );
  }

  atualizarSenha(mudarSenha: MudarSenhaRequest): Observable<MudarSenhaRequest> {
    return this.http.put<MudarSenhaRequest>( this.API_PROXY + 'private/v1/usuario/senha', mudarSenha, httpOptions);
  }


  /* Modality */
  listModality(): Observable<Array<Modality>>{
    return this.http.get<Array<Modality>>( this.API_PROXY + 'private/v1/modalidade' );
  }

  saveModality(modalidade: Modality): Observable<Modality> {
    return this.http.post<Modality>( this.API_PROXY + 'private/v1/modalidade', modalidade, httpOptions);
  }


}
