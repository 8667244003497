import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Event, ModelFactory, StatusEvent } from 'src/app/model';
import { ApiService } from 'src/app/shared';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {

  eventos: Array<Event> = [];
  //displayedColumns: string[] = ['descricao', 'inicio', 'fim', 'status'];
  displayedColumns: string[] = ['actions', 'nome', 'data_inicio', 'data_fim', 'status'];
  statusList: StatusEvent[] = [];
  isLoadingResults: boolean = false;
  searchForm: FormGroup;

  public RASCUNHO: number = ModelFactory.RASCUNHO;
	public INSCRICAO: number = ModelFactory.INSCRICAO;
  public EM_ANDAMENTO: number = ModelFactory.EM_ANDAMENTO;
  public APURACAO: number = ModelFactory.APURACAO;
	public FINALIZADO: number = ModelFactory.FINALIZADO;

  constructor(private apiService: ApiService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      nome : [null],
      evento_virtual : ['true', Validators.required],
      status : [{codigo: 0, descricao: "Vazio"}],
    });

    this.loadList();
  }


  private loadList(): void {
    this.isLoadingResults = true;
    this.apiService.listEvent()
    .subscribe((res: Array<Event>) => {
      this.eventos = res;
      this.isLoadingResults = false;
    }, err => {
      this.apiService.openSnackBarError(err.error.message);
      this.isLoadingResults = false;
    });

    this.apiService.listStatusEvent()
    .subscribe((statusEventos: StatusEvent[]) => {
      this.statusList = statusEventos;
    }, (err: any) => {
      console.log(err);
      this.apiService.openSnackBarError(err.error.message);
      this.isLoadingResults = false;
    });
  }


  compareStatusEvent(o1: StatusEvent, o2: StatusEvent): boolean {
    return o1.codigo === o2.codigo;
  }

  onFormSubmit() {
    this.isLoadingResults = true;    
    this.apiService.filterEvent(this.searchForm.value)
      .subscribe((res: any) => {
          this.isLoadingResults = false;
          this.eventos = res;
          this.apiService.openSnackBarSuccess('Busca realizada com sucesso!');
        }, (err: any) => {
          console.log(err);
          this.eventos = [];
          this.apiService.openSnackBarInfo(err.error.message);
          this.isLoadingResults = false;
        });        
  }

  limpar() {
    this.ngOnInit();
  }

  getEventRankingPDF(id: number) {
    this.isLoadingResults = true;
    this.apiService.getEventRankingPDF(id)
      .subscribe((data: any) => {
        this.isLoadingResults = false;
        this.downloadFile(data);
      }, err => {
        this.apiService.openSnackBarError(err.error.message);
        this.isLoadingResults = false;
      });   
  }

  getEventRankingGeralPDF(id: number) {
    this.isLoadingResults = true;
    this.apiService.getEventRankingGeralPDF(id)
      .subscribe((data: any) => {
        this.isLoadingResults = false;
        this.downloadFile(data);
      }, err => {
        console.log(err);
        this.apiService.openSnackBarError(err.error.message);
        this.isLoadingResults = false;
      });   
  }

  downloadFile(data) {
    const url = window.URL.createObjectURL(data);
    window.open(url);
  } 

  permiteAvancar(status: number):boolean{
    return status !== this.FINALIZADO;
  }

  permiteAvaliar(status: number):boolean{
    return status !== this.EM_ANDAMENTO && status !== this.APURACAO;
  }

  permiteRanking(status: number):boolean{
    return status !== this.FINALIZADO && status !== this.APURACAO;
  }


}
