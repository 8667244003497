import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelFactory, Event, Imagem } from 'src/app/model';
import { ApiService } from 'src/app/shared';

@Component({
  selector: 'app-event-banner',
  templateUrl: './event-banner.component.html',
  styleUrls: ['./event-banner.component.scss']
})
export class EventBannerComponent implements OnInit {

  isLoadingResults: boolean = false;
  nomeArquivo: string = '';
  file: File;
  evento: Event = ModelFactory.createEventEmpty();
  banners: Imagem[] =  null;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.nomeArquivo = null;
    this.getDetail(this.route.snapshot.params.id);
  }


  getDetail(id: number) {
    this.getEvent(id);
    this.getBanners(id);
  }

  getEvent(id: number) {
    this.apiService.getEvent(id)
      .subscribe((data: Event) => {
        this.evento = data;
        this.isLoadingResults = false;
      }, (err: any) => {
        console.log(err);
        this.apiService.openSnackBarError(err.error.message);
        this.isLoadingResults = false;
      });    
  }

  getBanners(id: number) {
    this.apiService.listBanners(id)
      .subscribe((data: Array<Imagem>) => {
        this.banners = data;
      }, (err: any) => {
        console.log(err);
      });    
  }


  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.file = file;
      this.nomeArquivo = file.name;
    }
  }

  upload(){
    this.isLoadingResults = true;
    this.apiService.uploadBanner(this.file, this.evento)
    .subscribe((res: Event) => {
      console.log(res);
      this.evento = res;
      this.isLoadingResults = false;
      this.apiService.openSnackBarSuccess('Banner incluído com sucesso');
      this.router.navigate(['/event-list']);
    }, err => {
      console.log(err);
      this.isLoadingResults = false;
      this.apiService.openSnackBarError(err.error.message);
    });
    
  }

}
