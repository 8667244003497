import { Observable } from 'rxjs';
import { User, Event, Category, Modality, ImportacaoInscricao, ModelFactory } from 'src/app/model';

export class MockData {
    
    getUser(user: User): Observable<User>{
      console.log('mock-user');
      return Observable.create((observer)=>{
        user.jwt = '123454d5d4fs65af4f4as8f7as987dfdas'
        user.id = '1';
        observer.next(user);
      });
    }
    
    getEvents(): Observable<Array<Event>> {
      console.log('mock-get-event');
      
      return Observable.create((observer) => {
          
        let _events: Array<Event> = [
          ModelFactory.createEvent(1, 'Corrida maluca - 2019', '', '2019-07-28T19:20:51.146Z', '2019-08-31T19:20:51.146Z', 'Em andamento', null, null, 'Natal', 1, true, null, null, null),
          ModelFactory.createEvent(2, 'Bike no descanso', '', '2020-07-28T19:20:51.146Z', '2020-08-31T19:20:51.146Z', 'Em andamento', null, null, 'Natal', 5, true, null, null, null),
          ModelFactory.createEvent(3, 'Corrida maluca - 2020', '', '2020-08-28T19:20:51.146Z', '2020-08-31T19:20:51.146Z', 'Inscrições abertas', null, null, 'Natal', 3, true, null, null, null),
          ModelFactory.createEvent(4, 'Natação - 2020', '', '2020-08-28T19:20:51.146Z', null, 'Inscrições abertas', null, null, 'Natal', 2, true, null, null, null)
        ];
        
        observer.next(_events);
      });
    }
    
    getCategories(): Observable<Category[]> {
      console.log('mock-get-category');
      
      return Observable.create((observer) => {
        
        let _events: Array<Category> = [
          ModelFactory.createCategory(1, 'Natação Geral M', 'NTM', 5, 100, 'M', '21km', null, null),
          ModelFactory.createCategory(2, 'Natação Geral F', 'NTF', 5, 100, 'F', '21km', null, null)        
        ];
        
        observer.next(_events);
      });
    }

    
  }