import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { first } from 'rxjs/operators';
import { TokenJWT, User } from 'src/app/model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() icon: string;
  //user: User;
  user: TokenJWT;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.authService.currentUser.subscribe(x => this.user = x);
  }


  logout() {
    this.authService.logout();
    this.router.navigate(['/logout']);
  }


}
