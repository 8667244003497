import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventRanking } from 'src/app/model';
import { ApiService } from 'src/app/shared';

@Component({
  selector: 'app-event-ranking',
  templateUrl: './event-ranking.component.html',
  styleUrls: ['./event-ranking.component.scss']
})
export class EventRankingComponent implements OnInit {

  isLoadingResults: boolean = false;
  rankingEvento: EventRanking = null;

  constructor(private route: ActivatedRoute, private apiService: ApiService, 
    private router: Router) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.getDetail(this.route.snapshot.params.id);
  }

  getDetail(id: number) {
    this.getEvent(id);
  }

  getEvent(id: number) {
    this.apiService.getEventRanking(id)
      .subscribe((data: EventRanking) => {
        this.rankingEvento = data;
        this.isLoadingResults = false;
      });    
  }

}
