import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';
import { ModelFactory, Event, StatusEvent } from 'src/app/model';
import { ApiService } from 'src/app/shared';

@Component({
  selector: 'app-event-next-status',
  templateUrl: './event-next-status.component.html',
  styleUrls: ['./event-next-status.component.scss']
})
export class EventNextStatusComponent implements OnInit {

  isLoadingResults: boolean = false;
  evento: Event = ModelFactory.createEventEmpty();
  statusList: StatusEvent[] = [];
  proximoStatus: StatusEvent = null; 

  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.getDetail(this.route.snapshot.params.id);
  }


  getDetail(id: number) {

    this.apiService.listStatusEvent()
    .subscribe((statusEventos: StatusEvent[]) => {
      this.statusList = statusEventos;
      this.getEvent(id);
    });

  }

  getEvent(id: number) {
    this.apiService.getEvent(id)
      .subscribe((data: Event) => {
        this.evento = data;
        this.preencherProximoStatus();
        this.isLoadingResults = false;
      }, (err: any) => {
        console.log(err);
        this.apiService.openSnackBarError(err.error.message);
        this.isLoadingResults = false;
      });
  }

  private preencherProximoStatus(){
    let proximoCodigo = this.evento.status.codigo + 1
    //this.proximoStatus = from(this.statusList).pipe(first((status)=> status.codigo==proximoCodigo, null));
    this.proximoStatus = this.statusList.filter((status)=> status.codigo==proximoCodigo)[0];
  }


  avancarStatus(){
    this.isLoadingResults = true;
    this.apiService.nextStatusEvent(this.evento.id)
    .subscribe((res: Event) => {
      console.log(res);
      this.evento = res;
      this.isLoadingResults = false;
      this.apiService.openSnackBarSuccess(`O evento avançou para o status ${this.evento.status.descricao}.`);
      this.router.navigate(['/event-list']);
    }, err => {
      console.log(err);
      this.isLoadingResults = false;
      this.apiService.openSnackBarError(err.error.message);
    });
    
  }

}
