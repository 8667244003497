import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modality-detail',
  templateUrl: './modality-detail.component.html',
  styleUrls: ['./modality-detail.component.scss']
})
export class ModalityDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
