import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Category, ModelFactory, Event } from 'src/app/model';
import { ApiService } from 'src/app/shared';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {

  categorias: Array<Category> = [];
  displayedColumns: string[] = ['actions', 'codigo', 'nome', 'percurso', 'sexo'];
  isLoadingResults: boolean = false;
  evento: Event = ModelFactory.createEventEmpty();

  constructor(private route: ActivatedRoute, private apiService: ApiService) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.loadList(this.route.snapshot.params.id);
    this.evento.id = this.route.snapshot.params.id;
  }

  private loadList(id: number): void {
    this.isLoadingResults = true;
    this.apiService.listCategories(id)
    .subscribe((res: Array<Category>) => {
      this.categorias = res;
      this.isLoadingResults = false;
    }, err => {
      this.apiService.openSnackBarError(err.error.message);
      this.isLoadingResults = false;
    });
  }

}
