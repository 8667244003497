<xcrono-card title="Inclusão de Modalidade" [loading]="isLoadingResults">

  <div class="button-row">
    <a mat-flat-button color="primary" [routerLink]="['/modality-list']"><mat-icon>list</mat-icon> Modalidade</a>
  </div>
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 

    <mat-card class="example-card" *ngIf="!isLoadingResults">
      <mat-card-header>
          
        <mat-card-title-group>
          <mat-card-title><h2>Dados da modalidade</h2></mat-card-title>
          <mat-card-subtitle>Informe o nome da modalidade.</mat-card-subtitle>
        </mat-card-title-group>
        
      </mat-card-header>
      <mat-card-content>

          <mat-card *ngIf="!isLoadingResults">
              <form [formGroup]="saveForm" (ngSubmit)="onFormSubmit()" class="form-container">
    
              <div fxLayout="row" fxLayoutGap="32px">
                
                <mat-form-field appearance="fill" fxFlex="100%">
                    <mat-label>Nome da modalidade</mat-label>
                    <input matInput placeholder="Nome" formControlName="nome" autofocus required maxlength="45">
                    <mat-error>
                        <span *ngIf="!saveForm.get('nome').valid && saveForm.get('nome').touched">Informe o nome da modalidade</span>
                    </mat-error>
                </mat-form-field>

              </div>
    
              <div class="button-row">
                <button type="submit" [disabled]="!saveForm.valid"  mat-flat-button color="warn"><mat-icon>playlist_add</mat-icon>Incluir</button>
              </div>
            </form>   
          </mat-card>  
      </mat-card-content>
    </mat-card>
  </div>




</xcrono-card>