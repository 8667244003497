import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Atividade, AvaliacaoAtividade, InscricaoView, ModelFactory, StatusAtividade, StatusAtividadeInscricao } from 'src/app/model';
import { ApiService } from 'src/app/shared';

@Component({
  selector: 'app-inscricao-edit',
  templateUrl: './inscricao-edit.component.html',
  styleUrls: ['./inscricao-edit.component.scss']
})
export class InscricaoEditComponent implements OnInit {

  public INSCRICAO_ATIVIDADE_ACEITA: number = ModelFactory.INSCRICAO_ATIVIDADE_ACEITA;
	public INSCRICAO_ATIVIDADE_REGISTRADA: number = ModelFactory.INSCRICAO_ATIVIDADE_REGISTRADA;
	public INSCRICAO_SEM_ATIVIDADE: number = ModelFactory.INSCRICAO_SEM_ATIVIDADE;
  
  public ATIVIDADE_RECEBIDA: number = ModelFactory.ATIVIDADE_RECEBIDA;
  public ATIVIDADE_APROVADA: number = ModelFactory.ATIVIDADE_APROVADA;
  public ATIVIDADE_REJEITADA: number = ModelFactory.ATIVIDADE_REJEITADA;
  public ATIVIDADE_DESCLASSIFICADO: number = ModelFactory.ATIVIDADE_DESCLASSIFICADO;


  isLoadingResults: boolean = false;
  inscricao: InscricaoView = null;
  atividades: Atividade[] = null;
  justificativa: string = null;
  
  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.getInscricaoAvaliacao(this.route.snapshot.params.id);
    this.getAtividadesInscricao(this.route.snapshot.params.id);
  }

  getInscricaoAvaliacao(id: number) {
    this.apiService.getInscricaoAvaliacao(id)
      .subscribe((data: InscricaoView) => {
        this.inscricao = data;
        //console.log(JSON.stringify(data));        
        this.isLoadingResults = false;
      }, (err: any) => {
        console.log(err);
        this.apiService.openSnackBarError(err.error.message);
        this.isLoadingResults = false;
      });
  }



  getAtividadesInscricao(id: number) {
    this.apiService.getAtividadesInscricao(id)
      .subscribe((data: Array<Atividade>) => {
        this.atividades = data;
        this.isLoadingResults = false;
      }, (err: any) => {
        console.log(err);
        this.apiService.openSnackBarError(err.error.message);
        this.isLoadingResults = false;
      });
  }



  registrarAprovacao(id: number){
    this.registrarAvaliacaoAtividade(id, ModelFactory.statusAtividadeAprovada());
  }
  registrarRejeicao(id: number){
    this.registrarAvaliacaoAtividade(id, ModelFactory.statusAtividadeRejeitada());
  }
  registrarDesclassificacao(id: number){
    this.registrarAvaliacaoAtividade(id, ModelFactory.statusAtividadeDesclassificada());
  }

  registrarAvaliacaoAtividade(id: number, status: StatusAtividade) {
    let avaliacao: AvaliacaoAtividade = {id_atividade: id, 
      id_incricao: this.inscricao.inscricao_id,      
      justificativa: this.justificativa,  
      status: status,
      inscricao: this.inscricao};

    this.apiService.avaliarAtividade(avaliacao)
      .subscribe((data: Atividade) => {
        this.router.navigate(['/event-rate', this.inscricao.evento_id]);
        this.isLoadingResults = false;
      }, (err: any) => {
        console.log(err);
        this.apiService.openSnackBarError(err.error.message);
        this.isLoadingResults = false;
      });
  }



  getClass(status: number){
    return (status === this.ATIVIDADE_APROVADA) ? 'bg-app-success' : 
           (status === this.ATIVIDADE_REJEITADA) ? 'bg-app-accent' : 
           (status === this.ATIVIDADE_DESCLASSIFICADO) ? 'bg-app-accent' : 'bg-app-secondary';
  }



  stepAtividade = -1;

  setStepAtividade(index: number) {
    this.stepAtividade = index;
  }

  nextStepAtividade() {
    this.stepAtividade++;
  }

  prevStepAtividade() {
    this.stepAtividade--;
  }

}
