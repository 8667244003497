<xcrono-card title="Manutenção de Atletas" [loading]="isLoadingResults">
  <div class="button-row">
    <!--
      <a mat-flat-button color="primary" [routerLink]="['/atleta-new']"><mat-icon>add</mat-icon> Atleta</a>
    -->
  </div>
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 

    <mat-table [dataSource]="atletas" class="mat-elevation-z8" *ngIf="!isLoadingResults"
      matSort matSortActive="email" matSortDisableClear matSortDirection="asc">

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Ações </mat-header-cell>
        <mat-cell *matCellDef="let row"> 
          <button mat-icon-button color="primary" [routerLink]="['/atleta-pass', row.id]" aria-label="Mudar a senha">
            <mat-icon>vpn_key</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- Email -->
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef> E-mail </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
      </ng-container>

      <!-- Ativo -->
      <ng-container matColumnDef="ativo">
        <mat-header-cell *matHeaderCellDef> Ativo </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.ativo | simNao}} </mat-cell>
      </ng-container>

      <!-- Data Criacao -->
      <ng-container matColumnDef="dataCriacao">
        <mat-header-cell *matHeaderCellDef> Data de criação </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.dataCriacao | date:'dd/MM/yyyy HH:mm:ss'}} </mat-cell>
      </ng-container>
        
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <!--
        <mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/atleta-detail/', row.id]"></mat-row>
      -->
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

  </div>
</xcrono-card>