import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelFactory, Event, Category, Modality } from 'src/app/model';
import { ApiService } from 'src/app/shared';

@Component({
  selector: 'app-category-new',
  templateUrl: './category-new.component.html',
  styleUrls: ['./category-new.component.scss']
})
export class CategoryNewComponent implements OnInit {

  isLoadingResults: boolean = false;
  modalidades: Array<Modality> = [];
  evento: Event = ModelFactory.createEventEmpty();
  categoria: Category = ModelFactory.createCategoryEmpty();
  saveForm: FormGroup;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.evento.id = this.route.snapshot.params.id;

    this.loadModalidadeList();

    /*this.saveForm = this.formBuilder.group({
      nome : [null, Validators.required],
      sexo : [null, Validators.required],
      idade_inicio : [null, Validators.required],
      idade_fim : [null, Validators.required],
      percurso : [null, Validators.required],
      codigo : [null, Validators.required],
      modalidade_id : [null, Validators.required],
      evento_id : [this.evento.id, Validators.required],

      
    });
    this.isLoadingResults = false;*/
  }

  createForm(){
    this.saveForm = this.formBuilder.group({
      nome : [null, Validators.required],
      sexo : [null, Validators.required],
      idade_inicio : [null, Validators.required],
      idade_fim : [null, Validators.required],
      percurso : [null, Validators.required],
      codigo : [null, Validators.required],
      modalidade_id : [null, Validators.required],
      evento_id : [this.evento.id, Validators.required],
    });
  }

  private loadModalidadeList(): void {
    this.isLoadingResults = true;
    this.apiService.listModality()
    .subscribe((res: Array<Modality>) => {
      this.modalidades = res;
      this.isLoadingResults = false;
      this.createForm();
    }, err => {
      console.log(err);
      this.apiService.openSnackBarError(err.error.message);
      this.isLoadingResults = false;
    });
  }

  onFormSubmit() {
    this.isLoadingResults = true;
    this.apiService.saveCategorY(this.saveForm.value)
      .subscribe((res: any) => {
          const id = res.id;
          this.isLoadingResults = false;
          this.apiService.openSnackBarSuccess('Categoria cadastrada com sucesso!');
          this.router.navigate(['/category-list', this.evento.id]);
        }, (err: any) => {
          console.log(err);
          this.apiService.openSnackBarError(err.error.message);
          this.isLoadingResults = false;
        });
  }

  /*

      id: number;
    nome: string;
    codigo: string;
    idadeInicio: number;
    idadeFim: number;
    sexo: string;
    percurso: string;
    modalidade: Modality;

    private Long id;
   	private String codigo;
	  private String nome;
  	private String sexo;
	  private Integer idade_inicio;
	  private Integer idade_fim;
  	private String percurso;
  	private String modalidade;
  	private Long modalidade_id;
  	private Long evento_id;

      */


}
