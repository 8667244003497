import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavbarComponent, CardComponent } from './';
import { AuthService, AuthGuardService,ApiService } from './';

import { MatToolbarModule } from '@angular/material/toolbar'; 
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GeneroPipe, SimNaoPipe } from './';

@NgModule({
  declarations: [
    //ApiService,
    //AuthService, 
    //AuthGuardService,
    NavbarComponent,
    CardComponent,
    GeneroPipe,
    SimNaoPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,

    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
  ],
  exports: [
   // ApiService,
   // AuthService, 
   // AuthGuardService,
    NavbarComponent,
    CardComponent,
    GeneroPipe,
    SimNaoPipe,
  ]
})
export class SharedModule { }
