import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent, LoginComponent, PageNotFoundComponent, LogoutComponent, 
  EventListComponent, EventNewComponent, EventEditComponent, EventDetailComponent, EventImportComponent,
  CategoryListComponent, CategoryDetailComponent, CategoryEditComponent, CategoryNewComponent, InscricaoEditComponent, 
  AtletaListComponent, EventNextStatusComponent, EventRateComponent, ModalityListComponent, ModalityDetailComponent, ModalityEditComponent, 
  ModalityNewComponent, EventBannerComponent, CategoryDelComponent, AtletaSenhaComponent, EventRankingComponent } from './view';
import { AuthGuardService } from './shared';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },

  { path: 'event-list', component: EventListComponent, canActivate: [AuthGuardService] },
  { path: 'event-new', component: EventNewComponent, canActivate: [AuthGuardService] },
  { path: 'event-edit/:id', component: EventEditComponent, canActivate: [AuthGuardService] },
  { path: 'event-detail/:id', component: EventDetailComponent, canActivate: [AuthGuardService] },
  { path: 'event-import/:id', component: EventImportComponent, canActivate: [AuthGuardService] },
  { path: 'event-rate/:id', component: EventRateComponent, canActivate: [AuthGuardService] },
  { path: 'event-next-status/:id', component: EventNextStatusComponent, canActivate: [AuthGuardService] },
  { path: 'event-banner/:id', component: EventBannerComponent, canActivate: [AuthGuardService] },
  { path: 'event-ranking/:id', component: EventRankingComponent, canActivate: [AuthGuardService] },

  { path: 'inscricao-edit/:id', component: InscricaoEditComponent, canActivate: [AuthGuardService] },
  
  { path: 'atleta-list', component: AtletaListComponent, canActivate: [AuthGuardService] },
  { path: 'atleta-pass/:id', component: AtletaSenhaComponent, canActivate: [AuthGuardService] },

  { path: 'category-list/:id', component: CategoryListComponent, canActivate: [AuthGuardService] },
  { path: 'category-new/:id', component: CategoryNewComponent, canActivate: [AuthGuardService] },
  { path: 'category-edit/:id', component: CategoryEditComponent, canActivate: [AuthGuardService] },
  { path: 'category-detail/:id', component: CategoryDetailComponent, canActivate: [AuthGuardService] },
  { path: 'category-del/:id', component: CategoryDelComponent, canActivate: [AuthGuardService] },

  { path: 'modality-list', component: ModalityListComponent, canActivate: [AuthGuardService] },
  { path: 'modality-new', component: ModalityNewComponent, canActivate: [AuthGuardService] },
  { path: 'modality-edit/:id', component: ModalityEditComponent, canActivate: [AuthGuardService] },
  { path: 'modality-detail/:id', component: ModalityDetailComponent, canActivate: [AuthGuardService] },
  
  
  { path: '', component: HomeComponent },
  { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
