import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared';

@Component({
  selector: 'app-modality-new',
  templateUrl: './modality-new.component.html',
  styleUrls: ['./modality-new.component.scss']
})
export class ModalityNewComponent implements OnInit {

  isLoadingResults: boolean = false;
  saveForm: FormGroup;

  constructor(private apiService: ApiService, private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.saveForm = this.formBuilder.group({
      nome : [null, Validators.required],
    });
    this.isLoadingResults = false;
  }

  onFormSubmit() {
    this.isLoadingResults = true;
    this.apiService.saveModality(this.saveForm.value)
      .subscribe((res: any) => {
          const id = res.id;
          this.isLoadingResults = false;
          this.apiService.openSnackBarSuccess('Modalidade cadastrada com sucesso!');
          this.router.navigate(['/modality-list']);
        }, (err: any) => {
          console.log(err);
          this.apiService.openSnackBarError(err.error.message);
          this.isLoadingResults = false;
        });
        
  }

}
