<xcrono-card title="Manutenção de Modalidades" [loading]="isLoadingResults">
  <div class="button-row">
    <a mat-flat-button color="primary" [routerLink]="['/modality-new']"><mat-icon>add</mat-icon> Modalidade</a>
  </div>
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 


    <mat-table [dataSource]="modalidades" class="mat-elevation-z8" *ngIf="!isLoadingResults"
      matSort matSortActive="nome" matSortDisableClear matSortDirection="asc">

      <!-- Descrição -->
      <ng-container matColumnDef="nome">
        <mat-header-cell *matHeaderCellDef> Descrição </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.nome}} </mat-cell>
      </ng-container>
        
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/modality-detail/', row.id]"></mat-row>
    </mat-table>




  </div>
</xcrono-card>