<mat-toolbar color="primary" class="mat-elevation-z6">
  <!--
    <img src="{{icon}}"> 
    <img ng-src="icon"> 
    <img [src]="icon"> 
  -->
  <span routerLink="/home"> <img [src]="icon"> </span>
  
  <span class="spacer"></span>
    
  <span *ngIf="!user" class="button-row">
    <a mat-raised-button color="warn" routerLink="/login"> Login </a>
  </span>

    
  <span *ngIf="user" class="button-row" fxLayout="row">
    <button mat-raised-button color="warn" [matMenuTriggerFor]="beforeMenu" style="margin-right: 15px;">Utilitários</button>
    <mat-menu #beforeMenu="matMenu" xPosition="before">
      <a mat-button routerLink="/home"> Home </a><br>
      <a mat-button routerLink="/event-list"> Eventos </a><br>
      <a mat-button routerLink="/event-new"> Novo evento </a><br>
      <!--
        <a mat-button routerLink="/category-list"> Categorias </a><br>
      -->
      <a mat-button routerLink="/modality-list"> Modalidades </a><br>
      <a mat-button routerLink="/atleta-list"> Atletas </a><br>
    </mat-menu>
    
    <button mat-mini-fab color="accent" [matMenuTriggerFor]="loginMenu"><mat-icon>person</mat-icon></button>
    <mat-menu #loginMenu="matMenu" xPosition="before">
      <a mat-button routerLink="/home"> {{user.user}} </a><br>
      <a mat-button (click)="logout()"> Logout </a>
    </mat-menu>
  </span>


    

</mat-toolbar>

