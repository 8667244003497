import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/shared';
import { Router, ActivatedRoute } from '@angular/router';
import { Event, ModelFactory, StatusEvent } from 'src/app/model';

@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.scss']
})
export class EventEditComponent implements OnInit {

  isLoadingResults: boolean = false;
  statusList: StatusEvent[] = [];
  saveForm: FormGroup;
  evento: Event = ModelFactory.createEventEmpty();

  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.getDetail(this.route.snapshot.params.id);
  }


  getDetail(id: number) {

    this.apiService.listStatusEvent()
    .subscribe((statusEventos: StatusEvent[]) => {
      this.statusList = statusEventos;
      this.getEvent(id);
    }, (err: any) => {
      console.log(err);
      this.apiService.openSnackBarError(err.error.message);
      this.isLoadingResults = false;
    });

  }

  getEvent(id: number) {
    this.apiService.getEvent(id)
      .subscribe((data: Event) => {
        this.evento = data;
        //console.log(this.evento);
        this.saveForm = this.formBuilder.group({
          id : [data.id],
          nome : [data.nome, Validators.required],
          descricao : [data.descricao, Validators.required],
          data_inicio : [data.data_inicio, Validators.required],
          data_fim : [data.data_fim, Validators.required],
          data_inicio_envio : [data.data_inicio_envio, Validators.required],
          data_fim_envio : [data.data_fim_envio, Validators.required],
          evento_virtual : [data.evento_virtual, Validators.required],
          cidade : [data.cidade, Validators.required],
          status : [data.status, Validators.required],
          quantidade_imagem : [data.quantidade_imagem, Validators.required],
        });
        this.isLoadingResults = false;
      }, (err: any) => {
        console.log(err);
        this.apiService.openSnackBarError(err.error.message);
        this.isLoadingResults = false;
      });    
  }

  compareStatusEvent(o1: StatusEvent, o2: StatusEvent): boolean {
    return o1.codigo === o2.codigo;
  }

  onFormSubmit() {
    this.isLoadingResults = true;
    this.apiService.updateEvent(this.saveForm.value)
      .subscribe((res: any) => {
          const id = res.id;
          this.isLoadingResults = false;
          this.apiService.openSnackBarSuccess('Evento atualizado com sucesso!');
          this.router.navigate(['/event-list']);
          //this.router.navigate(['/event-detail', id]);
        }, (err: any) => {
          console.log(err);
          this.apiService.openSnackBarError(err.error.message);
          this.isLoadingResults = false;
        });
        
  }

  

}
