import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User, TokenJWT } from 'src/app/model';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private USER_KEY : string = 'currentUser'; 
  private currentUserSubject: BehaviorSubject<TokenJWT>;
  public currentUser: Observable<TokenJWT>;

  constructor(private api: ApiService) {
    this.currentUserSubject = new BehaviorSubject<TokenJWT>(JSON.parse(localStorage.getItem(this.USER_KEY)));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): TokenJWT {
    return this.currentUserSubject.value;
  }

  public get logged() {
    // check if jwt token is in auth header

    const _jwtToken : TokenJWT = this.currentUserValue;
    const isLoggedIn = _jwtToken && _jwtToken.access_token;

    if(!isLoggedIn){
      return false;
    }
    
    const body = JSON.parse( atob(_jwtToken.access_token.split('.')[1]) );
    const tokenExpired = Date.now() > (body.exp * 1000);
    
    //-----------------
    //console.log(body.exp);
    //const date = new Date(0);
    //date.setUTCSeconds(body.exp);
    //console.log(date);
    //-----------------

    //const authHeader = headers.get('Authorization');
    //if (!authHeader.startsWith('Bearer fake-jwt-token')) return false;

    // check if token is expired
    //const jwtToken = JSON.parse(atob(jwtToken_.token.split('.')[1]));
    //const jwtToken = JSON.parse(atob(authHeader.split('.')[1]));
    //const tokenExpired = Date.now() > (jwtToken.exp * 1000);
    if (tokenExpired){
      this.logout();
      return false;
    } 

    return true;
  }

  login(email: string, password: string) {
    return this.api.login({username: email, password: password, grant_type:'password' })
          .pipe(map(token => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          token.user = email;
          const admin =  token.roles.filter((role)=>role['authority']=="ADM");
          if(admin.length > 0){
            localStorage.setItem(this.USER_KEY, JSON.stringify(token));
            this.currentUserSubject.next(token);
            return token;
          }
          
          //localStorage.setItem(this.USER_KEY, JSON.stringify(token));
          //this.currentUserSubject.next(token);
          //return token;
          this.api.openSnackBarError('O usuário não tem acesso a essa área.');
          return null;
    }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(this.USER_KEY);
    this.currentUserSubject.next(null);
  }
  
}
