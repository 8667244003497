<xcrono-card title="Edição do Evento" [loading]="isLoadingResults">

  <div class="button-row">
    <a mat-flat-button color="primary" [routerLink]="['/event-list']"><mat-icon>list</mat-icon> Evento</a>
  </div>
  
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 

    <mat-card class="example-card" *ngIf="!isLoadingResults">
      <mat-card-header>
          
        <mat-card-title-group>
          <mat-card-title><h2>Dados do evento</h2></mat-card-title>
          <mat-card-subtitle>Informe todos os dados do evento.</mat-card-subtitle>
        </mat-card-title-group>
        
      </mat-card-header>
      <mat-card-content>

          <mat-card *ngIf="!isLoadingResults">
              <form [formGroup]="saveForm" (ngSubmit)="onFormSubmit()" class="form-container">
    
              <div fxLayout="row" fxLayoutGap="32px">
                
                <mat-form-field appearance="fill" fxFlex="80%">
                    <mat-label>Nome do evento</mat-label>
                    <input matInput placeholder="Nome" formControlName="nome" autofocus required maxlength="45">
                    <mat-error>
                        <span *ngIf="!saveForm.get('nome').valid && saveForm.get('nome').touched">Informe o nome do evento</span>
                    </mat-error>
                </mat-form-field>

                <div fxFlex="20%" fxFlexAlign="center">
                  <mat-slide-toggle formControlName="evento_virtual">Evento virtual</mat-slide-toggle> 
                </div>

              </div>

              <div fxLayout="row" fxLayoutGap="32px">
                <mat-form-field appearance="fill" fxFlex="80%">
                  <mat-label>Cidade</mat-label>
                  <input matInput placeholder="Cidade" formControlName="cidade" maxlength="20">
                  <mat-error>
                      <span *ngIf="!saveForm.get('cidade').valid && saveForm.get('cidade').touched">Informe a cidade do evento.</span>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="20%">
                  <mat-label>Número de imagens</mat-label>
                  <input matInput placeholder="Número de imagens" formControlName="quantidade_imagem" maxlength="20" type="number">
                  <mat-error>
                      <span *ngIf="!saveForm.get('quantidade_imagem').valid && saveForm.get('quantidade_imagem').touched">Informe o número de imagens.</span>
                  </mat-error>
                </mat-form-field>
              </div>
              

              <div fxLayout="row" fxLayoutGap="32px">
                <mat-form-field appearance="fill" fxFlex="50%">
                  <mat-label>Data de início do evento</mat-label>
                  <input matInput placeholder="Data de início" formControlName="data_inicio" maxlength="10" [matDatepicker]="dataInicio">
                  <mat-datepicker-toggle matSuffix [for]="dataInicio"></mat-datepicker-toggle>
                  <mat-datepicker #dataInicio></mat-datepicker>
                  <mat-error>
                      <span *ngIf="!saveForm.get('data_inicio').valid && saveForm.get('data_inicio').touched">Informe a data de início do evento</span>
                  </mat-error>
                </mat-form-field>  
                <mat-form-field appearance="fill" fxFlex="50%">
                  <mat-label>Data de fim do evento</mat-label>
                  <input matInput placeholder="Data de fim" formControlName="data_fim" maxlength="10"  [matDatepicker]="dataFim">
                  <mat-datepicker-toggle matSuffix [for]="dataFim"></mat-datepicker-toggle>
                  <mat-datepicker #dataFim></mat-datepicker>
                  <mat-error>
                      <span *ngIf="!saveForm.get('data_fim').valid && saveForm.get('data_fim').touched">Informe a data de fim do evento</span>
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="32px">
                <mat-form-field appearance="fill" fxFlex="50%">
                  <mat-label>Data de início do envio da atividade</mat-label>
                  <input matInput placeholder="Data de início do envio" formControlName="data_inicio_envio" maxlength="10" [matDatepicker]="envioInicio">
                  <mat-datepicker-toggle matSuffix [for]="envioInicio"></mat-datepicker-toggle>
                  <mat-datepicker #envioInicio></mat-datepicker>
                  <mat-error>
                      <span *ngIf="!saveForm.get('data_inicio_envio').valid && saveForm.get('data_inicio_envio').touched">Informe a data de início do envio</span>
                  </mat-error>
                </mat-form-field>  
                <mat-form-field appearance="fill" fxFlex="50%">
                  <mat-label>Data de fim do envio da atividade</mat-label>
                  <input matInput placeholder="Data de fim do envio" formControlName="data_fim_envio" maxlength="10" [matDatepicker]="envioFim">
                  <mat-datepicker-toggle matSuffix [for]="envioFim"></mat-datepicker-toggle>
                  <mat-datepicker #envioFim></mat-datepicker>
                  <mat-error>
                      <span *ngIf="!saveForm.get('data_fim_envio').valid && saveForm.get('data_fim_envio').touched">Informe a data de fim do envio</span>
                  </mat-error>
                </mat-form-field>
              </div>

              <mat-form-field>
                <mat-label>Descrição para o evento</mat-label>
                <textarea matInput formControlName="descricao"
                          placeholder="Digite a justificativa para a mudança de status" 
                          rows="6" maxlength="2500" required>
                </textarea>
                <mat-error>
                    <span *ngIf="!saveForm.get('descricao').valid && saveForm.get('descricao').touched">Informe a descrição para o evento</span>
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" [compareWith]="compareStatusEvent">
                  <mat-option *ngFor="let status of statusList" [value]="status">
                    {{status.descricao}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
    
              <div class="button-row">
                <button type="submit" [disabled]="!saveForm.valid"  mat-flat-button color="warn"><mat-icon>playlist_add</mat-icon>Editar</button>
              </div>
            </form>   
          </mat-card>  
      </mat-card-content>
    </mat-card>
  </div>

</xcrono-card>