<mat-card class="mat-elevation-z3">
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="submit()">
        <h2>Login</h2>
        <mat-error *ngIf="loginInvalid">
          As credenciais informadas estão inválidas.
        </mat-error>
        <mat-form-field class="full-width-input">
          <input matInput type="email" placeholder="E-mail" formControlName="email" required>
          <mat-error>
            Informe o e-mail.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput type="password" placeholder="Senha" formControlName="password" required>
          <mat-error>
            Informe a senha.
          </mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary">Login</button>
      </form>
    </mat-card-content>
  </mat-card>