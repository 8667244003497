<xcrono-card title="Manutenção de Categorias" [loading]="isLoadingResults">
  <div fxLayout="row" fxLayoutGap="6px" *ngIf="!isLoadingResults">
    <a mat-flat-button color="primary" [routerLink]="['/category-new', evento.id]"><mat-icon>add</mat-icon> Categoria</a>
    <a mat-flat-button color="accent" [routerLink]="['/event-list']"><mat-icon>arrow_back</mat-icon> Evento</a>
  </div>
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 

    <mat-table [dataSource]="categorias" class="mat-elevation-z8" *ngIf="!isLoadingResults"
      matSort matSortActive="nome" matSortDisableClear matSortDirection="asc">

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Ações </mat-header-cell>
        <mat-cell *matCellDef="let row"> 

          <button mat-icon-button [matMenuTriggerFor]="menuCategoria" aria-label="Menu de opções da categoria">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menuCategoria="matMenu">
            <button mat-menu-item matTooltip="Click para remover" class="iconbutton" color="primary" [routerLink]="['/category-del', row.id]">
              <mat-icon>clear</mat-icon>
              <span>Remover</span>
            </button>
          </mat-menu>

        </mat-cell>
      </ng-container>

      <!-- Código -->
      <ng-container matColumnDef="codigo">
        <mat-header-cell *matHeaderCellDef> Código </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.codigo}} </mat-cell>
      </ng-container>
      
      <!-- Nome -->
      <ng-container matColumnDef="nome">
        <mat-header-cell *matHeaderCellDef> Nome </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.nome}} </mat-cell>
      </ng-container>

      <!-- Percurso -->
      <ng-container matColumnDef="percurso">
        <mat-header-cell *matHeaderCellDef> Percurso </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.percurso}} </mat-cell>
      </ng-container>

      <!-- Sexo -->
      <ng-container matColumnDef="sexo">
        <mat-header-cell *matHeaderCellDef> Sexo </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.sexo | genero}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <!--
        <mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/category-detail/', row.id]"></mat-row>
      -->
    </mat-table>



  </div>
</xcrono-card>