<xcrono-card title="Informações do Evento" [loading]="isLoadingResults">

  <div class="button-row">
    <a mat-flat-button color="primary" [routerLink]="['/event-list']"><mat-icon>list</mat-icon> Evento</a>
    <a mat-flat-button color="accent" [routerLink]="['/event-edit', evento.id]"><mat-icon>add</mat-icon> Editar Evento </a>
  </div>
  
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 

    <mat-card class="example-card">
      <mat-card-header>          
        <mat-card-title-group>
          <mat-card-title><h2>Evento{{evento.evento_virtual ? ' Virtual' : ''}}: {{evento.nome}} </h2></mat-card-title>
          <mat-card-subtitle>{{evento.descricao}}.</mat-card-subtitle>
        </mat-card-title-group>        
      </mat-card-header>
      <mat-card-content>

        <mat-card class="card-data">
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="10px">
            <div fxFlexOffset="8" fxFlex="15%">Data início:</div>
            <div fxFlexOffset="0" fxFlex="45%">{{evento.data_inicio | date:'dd/MM/yyyy'}}</div>
            <div fxFlexOffset="0" fxFlex="15%">Data fim:</div>
            <div fxFlexOffset="0" fxFlex="25%">{{evento.data_fim | date:'dd/MM/yyyy'}}</div>
          </div>
        </mat-card>
        <mat-card class="card-data">
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="10px">
            <div fxFlexOffset="8" fxFlex="15%">Data início envio:</div>
            <div fxFlexOffset="0" fxFlex="45%">{{evento.data_inicio_envio | date:'dd/MM/yyyy'}}</div>
            <div fxFlexOffset="0" fxFlex="15%">Data fim envio:</div>
            <div fxFlexOffset="0" fxFlex="25%">{{evento.data_fim_envio | date:'dd/MM/yyyy'}}</div>
          </div>
        </mat-card>
        <mat-card class="card-data">
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="10px">
            <div fxFlexOffset="8" fxFlex="10%">Cidade:</div>
            <div fxFlexOffset="0" fxFlex="45%">{{evento.cidade}}</div>
            <div fxFlexOffset="0" fxFlex="15%">Status:</div>
            <div fxFlexOffset="0" fxFlex="25%">{{evento.status?.descricao}}</div>
          </div>
        </mat-card>
        
        <mat-tab-group mat-align-tabs="center">
          <!-- CATEGORIAS -->
          <mat-tab label="Categorias ({{evento.categorias?.length}})">
            <mat-accordion *ngFor="let categoria of evento.categorias; let i = index">
              <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i)" hideToggle>
                  <mat-expansion-panel-header fxLayout="row" >
                    <mat-panel-title>{{categoria.nome}}</mat-panel-title>
                    <mat-panel-description dir="rtl">{{categoria.codigo}}</mat-panel-description>
                    <mat-icon>more_vert</mat-icon>
                  </mat-expansion-panel-header>
    
                  <mat-list >
                    <div mat-subheader>{{categoria.modalidade}} [{{categoria.sexo | genero}}]</div>
                    <mat-divider></mat-divider>
                    <mat-list-item>
                      <mat-icon mat-list-icon>dashboard</mat-icon>
                      <div mat-line> Faixa etária: {{categoria.idade_inicio}} até {{categoria.idade_fim}} anos</div>
                      <div mat-line> Percurso: {{categoria.percurso}} </div>
                      <mat-divider></mat-divider>  
                    </mat-list-item>
                  </mat-list>
                  
                </mat-expansion-panel>
            </mat-accordion>
          </mat-tab>  

          <!-- INSCRICOES -->
          <mat-tab label="Inscrições ({{evento.inscricoes?.length}})" *ngIf="!isLoadingResults">
            <mat-accordion *ngFor="let inscricao of evento.inscricoes let i = index">
              <mat-expansion-panel [expanded]="stepInscricao === i" (opened)="setStepInscricao(i)" hideToggle>
                  <mat-expansion-panel-header fxLayout="row" >
                    <mat-panel-title>[{{inscricao.inscricao_id}}] {{inscricao.inscricao_nome}}</mat-panel-title>
                    <mat-panel-description dir="rtl">Número de Peito {{inscricao.numero_peito}}</mat-panel-description>
                    <mat-icon>more_vert</mat-icon>
                  </mat-expansion-panel-header>
    
                  <mat-list >
                    <div mat-subheader>{{inscricao.modalidade_nome}} [{{inscricao.percurso}} / {{inscricao.categoria_sexo | genero}} / {{inscricao.idade_inicio}} até {{inscricao.idade_fim}} anos] - {{inscricao.categoria_nome}} - {{inscricao.categoria_codigo}}</div>
                    <mat-divider></mat-divider>
                    <mat-list-item>
                      <mat-icon mat-list-icon>face</mat-icon>
                      <div mat-line> E-mail: {{inscricao.email}} </div>
                      <div mat-line> CPF: {{inscricao.cpf}} </div>
                      <div mat-line> Idade: {{inscricao.idade}} [{{inscricao.data_nascimento | date : 'dd/MM/yyyy'}}] </div>
                      <div mat-line> Sexo: {{inscricao.sexo | genero}} </div>
                      <div mat-line> Celular: {{inscricao.celular}} </div>
                      <div mat-line> Equipe: {{inscricao.equipe}} </div>
                      <div mat-line> Percurso: {{inscricao.percurso}} </div>
                    </mat-list-item>
                  </mat-list>
                </mat-expansion-panel>
            </mat-accordion>    
          </mat-tab>  
    
          <!-- IMPORTACOES -->
          <mat-tab label="Importações ({{evento.importacoes?.length}})">
            <mat-list *ngFor="let importacao of evento.importacoes; let i = index">
              <div mat-subheader>Importacao {{importacao.id}} - [ {{importacao.dataImportacao | date : 'dd/MM/yyyy - H:mm:ss'}} ]</div>
              <mat-divider></mat-divider>
              <mat-list-item>
                <div mat-line> O arquivo {{importacao.nomeArquivo}} importou {{importacao.totalInscricoes}} inscrições.</div>
                <mat-divider></mat-divider>
              </mat-list-item>
              <mat-card *ngIf="importacao.mensagem"> {{importacao.mensagem}}.</mat-card>
            </mat-list>
          </mat-tab>  
    
        </mat-tab-group>  
    
    
        

      </mat-card-content>  
    </mat-card>  
    
  </div>
</xcrono-card>
