<xcrono-card title="Avançar o status do Evento" [loading]="isLoadingResults">

  <div class="button-row">
    <a mat-flat-button color="primary" [routerLink]="['/event-list']"><mat-icon>list</mat-icon> Evento</a>
  </div>
  
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 

    <mat-card *ngIf="!isLoadingResults">
      <mat-card-header>
          
        <mat-card-title-group>
          <mat-card-title><h2>Evento{{evento.evento_virtual ? ' Virtual' : ''}}: {{evento.nome}} </h2></mat-card-title>
            <mat-card-subtitle>{{evento.descricao}}.</mat-card-subtitle>
        </mat-card-title-group>
        
      </mat-card-header>
      <mat-card-content>

        <mat-card>
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="10px">
            <div fxFlexOffset="8" fxFlex="100%">Status atual: {{evento.status.descricao}} </div>
          </div>
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="10px" *ngIf="proximoStatus">
            <div fxFlexOffset="8" fxFlex="100%">Próximo status: {{proximoStatus.descricao}} </div>
          </div>
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="10px" *ngIf="!proximoStatus">
            <div fxFlexOffset="8" fxFlex="100%"> Não é possível mudar o status do evento. </div>
          </div>
        </mat-card>
        <div class="button-row">
          <button type="button" mat-flat-button color="warn" (click)="avancarStatus()" [disabled]="!proximoStatus"><mat-icon>skip_next</mat-icon> &nbsp; Avançar</button>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
</xcrono-card>
