import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-new',
  templateUrl: './event-new.component.html',
  styleUrls: ['./event-new.component.scss']
})
export class EventNewComponent implements OnInit {

  isLoadingResults: boolean = false;
  saveForm: FormGroup;

  constructor(private apiService: ApiService, private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.saveForm = this.formBuilder.group({
      nome : [null, Validators.required],
      descricao : [null, Validators.required],
      data_inicio : [null, Validators.required],
      data_fim : [null, Validators.required],
      data_inicio_envio : [null, Validators.required],
      data_fim_envio : [null, Validators.required],
      evento_virtual : ['true', Validators.required],
      cidade : ['', Validators.required],
      quantidade_imagem : ['3', Validators.required]
    });
    this.isLoadingResults = false;
  }

  onFormSubmit() {
    this.isLoadingResults = true;
    this.apiService.saveEvent(this.saveForm.value)
      .subscribe((res: any) => {
          const id = res.id;
          this.isLoadingResults = false;
          this.apiService.openSnackBarSuccess('Evento cadastrado com sucesso!');
          this.router.navigate(['/event-list']);
          //this.router.navigate(['/event-detail', id]);
        }, (err: any) => {
          console.log(err);
          this.apiService.openSnackBarError(err.error.message);
          this.isLoadingResults = false;
        });
        
  }


}
