<xcrono-card title="Manutenção de Eventos" [loading]="isLoadingResults">
  
  <mat-card  *ngIf="!isLoadingResults" style="margin-bottom: 20px;">
    <mat-card-header>        
      <mat-card-title>Busca de evento</mat-card-title>
    </mat-card-header>
    <form [formGroup]="searchForm" (ngSubmit)="onFormSubmit()" class="form-container">
      <mat-card-content>
        <div fxLayout="row" fxLayoutGap="32px">
          
          <mat-form-field appearance="fill" fxFlex="40%">
              <mat-label>Nome do evento</mat-label>
              <input matInput placeholder="Nome" formControlName="nome" maxlength="45">             
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex="40%">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status" [compareWith]="compareStatusEvent">              
              <mat-option *ngFor="let status of statusList" [value]="status">
                {{status.descricao}}
              </mat-option>
            </mat-select>
          </mat-form-field>


          <div fxFlex="20%" fxFlexAlign="center">
            <mat-slide-toggle formControlName="evento_virtual">Evento virtual</mat-slide-toggle> 
          </div>

        </div>          
      </mat-card-content>
      <mat-card-actions align='center' fxLayout="row" fxLayoutGap="6px" class="button-row">
        <button type="button" (click)="limpar()" mat-flat-button color="primary"><mat-icon>search_off</mat-icon>Limpar</button>      
        <button type="submit" [disabled]="!searchForm.valid"  mat-flat-button color="warn"><mat-icon>search</mat-icon>Buscar</button>      
      </mat-card-actions>
    </form>   
  </mat-card>

  <div fxLayout="row" fxLayoutGap="6px" class="button-row">
    <a mat-flat-button color="primary" [routerLink]="['/event-new']"><mat-icon>add</mat-icon> Evento</a>
    <a mat-flat-button color="accent" [routerLink]="['/event-stat']"><mat-icon>bar_chart</mat-icon> Estatísticas</a>
  </div>
  <div class="mat-elevation-z8" *ngIf="!isLoadingResults"> 
        
    <mat-table [dataSource]="eventos" class="mat-elevation-z8" *ngIf="!isLoadingResults"
      matSort matSortActive="name" matSortDisableClear matSortDirection="asc">

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Ações </mat-header-cell>
        <mat-cell *matCellDef="let row"> 
          <!--
            <button mat-icon-button matTooltip="Click para Visualizar" class="iconbutton" color="primary" [routerLink]="['/event-detail', row.id]">
              <mat-icon aria-label="Visualizar">more_vert</mat-icon>
            </button>
          -->



          <button mat-icon-button [matMenuTriggerFor]="menuEvento" aria-label="Menu de opções do evento">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menuEvento="matMenu">
            <button mat-menu-item matTooltip="Click para Visualizar" class="iconbutton" color="primary" [routerLink]="['/event-detail', row.id]">
              <mat-icon>more_horiz</mat-icon>
              <span>Detalhar</span>
            </button>
            <button mat-menu-item matTooltip="Click para Visualizar" class="iconbutton" color="primary" [routerLink]="['/event-edit', row.id]">
              <mat-icon>add</mat-icon>
              <span>Editar</span>
            </button>

            <button mat-menu-item matTooltip="Click para Visualizar" class="iconbutton" color="primary" [routerLink]="['/category-list', row.id]">
              <mat-icon>list</mat-icon>
              <span>Categorias</span>
            </button>

            <button mat-menu-item matTooltip="Click para Visualizar" class="iconbutton" color="primary" [routerLink]="['/event-import', row.id]">
              <mat-icon>cloud_upload</mat-icon>
              <span>Importar</span>
            </button>

            <button mat-menu-item matTooltip="Click para inserir um banner" class="iconbutton" color="primary" [routerLink]="['/event-banner', row.id]">
              <mat-icon>add_a_photo</mat-icon>
              <span>Banner</span>
            </button>
           
            <button mat-menu-item [disabled]="!permiteAvancar(row.status.codigo)" matTooltip="Click para Avaliar" class="iconbutton" color="primary" [routerLink]="['/event-next-status', row.id]">
              <mat-icon>skip_next</mat-icon>
              <span>Avançar</span>
            </button>

            <button mat-menu-item [disabled]="permiteAvaliar(row.status.codigo)" matTooltip="Click para Avaliar" class="iconbutton" color="primary" [routerLink]="['/event-rate', row.id]">
              <mat-icon>rule</mat-icon>
              <span>Avaliar</span>
            </button>

            <button mat-menu-item [disabled]="permiteRanking(row.status.codigo)" matTooltip="Vizualizar Ranking" class="iconbutton" color="primary" [routerLink]="['/event-ranking', row.id]">
              <mat-icon>alarm_on</mat-icon>
              <span>Ranking</span>
            </button>

            <button mat-menu-item [disabled]="permiteRanking(row.status.codigo)" matTooltip="Baixar Ranking" class="iconbutton" color="primary" (click)="getEventRankingPDF(row.id)">
              <mat-icon>picture_as_pdf</mat-icon>
              <span>Ranking PDF</span>
            </button>

            <button mat-menu-item [disabled]="permiteRanking(row.status.codigo)" matTooltip="Baixar Ranking Geral" class="iconbutton" color="primary" (click)="getEventRankingGeralPDF(row.id)">
              <mat-icon>picture_as_pdf</mat-icon>
              <span>Ranking Greal PDF</span>
            </button>

            <button mat-menu-item disabled>
              <mat-icon>clear</mat-icon>
              <span>Remover</span>
            </button>
          </mat-menu>




        </mat-cell>
      </ng-container>

      <!-- Descrição -->
      <ng-container matColumnDef="nome">
        <mat-header-cell *matHeaderCellDef> Descrição </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.nome}} </mat-cell>
      </ng-container>
        
      <!-- Início -->
      <ng-container matColumnDef="data_inicio">
        <mat-header-cell *matHeaderCellDef> Inicio </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.data_inicio | date:'dd/MM/yyyy'}} </mat-cell>
      </ng-container>
        
      <!-- Fim -->
      <ng-container matColumnDef="data_fim">
        <mat-header-cell *matHeaderCellDef> Fim </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.data_fim | date:'dd/MM/yyyy'}} </mat-cell>
      </ng-container>
        
      <!-- Status -->
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.status.descricao}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <!--
        <mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/event-detail/', row.id]"></mat-row>
      -->
    </mat-table>
  </div>

</xcrono-card>
