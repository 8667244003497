import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, AuthService } from 'src/app/shared';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  form: FormGroup;
  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;
  private returnUrl: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private apiService: ApiService) {
      // redirect to home if already logged in
      if (this.authService.currentUserValue) { 
        this.router.navigate(['/']);
      }
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

    // get return url from route parameters or default to '/'
    //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  
    this.form = this.fb.group({
      email: ['', Validators.email],
      password: ['', Validators.required]
    });
  
  }

  get f() { return this.form.controls; }

  submit() {
    this.loginInvalid = false;
    this.formSubmitAttempt = false;
    if (this.form.valid) {
      this.loading = true;
      this.authService.login(this.f.email.value, this.f.password.value)
        .pipe(first())
        .subscribe(data => {
          if(data){
            this.loading = false;
            this.router.navigate([this.returnUrl]);
          }
        },
        error => {
          this.error = error;
          this.loading = false;
          this.apiService.openSnackBarError('Login ou senha inválida.');
        });
    } else {
      this.formSubmitAttempt = true;
    }
  }




  loading = false;
  submitted = false;
  error = '';

}
