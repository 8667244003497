import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

//import { environment } from '@environments/environment';
import { AuthService } from './auth.service';
import { TokenJWT } from '../../model';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) { }

  intercept_old(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('interceptando http');
        
    // add auth header with jwt if user is logged in and request is to the api url
    const jwtToken : TokenJWT = this.authService.currentUserValue;
    const isLoggedIn = jwtToken && jwtToken.access_token;
    //const isApiUrl = request.url.startsWith(environment.apiUrl);
    //filtrar apenas as rotas privadas
    if (isLoggedIn /*&& isApiUrl*/) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${jwtToken.access_token}` }
      });
    }

    return next.handle(request);
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    console.log('Executando handleAuthError ............ ');
    
    if (err.status === 401 || err.status === 403) {
        //navigate /delete cookies or whatever
        this.authService.logout();
        this.router.navigateByUrl(`/login`);
        // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
        return of(err.message); // or EMPTY may be appropriate here
    //} else if (err.status === 404) {

    }
    return throwError(err);
  }




















  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const jwtToken : TokenJWT = this.authService.currentUserValue;
    const isLoggedIn = jwtToken && jwtToken.access_token;
    //const isApiUrl = request.url.startsWith(environment.apiUrl);
    //filtrar apenas as rotas privadas
    if (isLoggedIn /*&& isApiUrl*/) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${jwtToken.access_token}` }
      });
    }

    return next.handle(request).pipe( tap(() => {},
      (err: any) => {
      if (err instanceof HttpErrorResponse) {
        // if (err.status === 404) { } else
        if (err.status !== 401 && err.status !== 403) {
         return;
        }
        this.authService.logout();
        this.router.navigate(['login']);
      }
    }));
  }


}