import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genero'
})
export class GeneroPipe implements PipeTransform {

  transform(sexo: string, ...args: unknown[]): string {
    if(sexo === 'M' || sexo === 'm'){
      return 'Masculino';
    } else if(sexo === 'F' || sexo === 'f'){
      return 'Feminino';
    }
    return '';
  }

}
