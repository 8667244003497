import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Atleta } from 'src/app/model';
import { ApiService } from 'src/app/shared';

@Component({
  selector: 'app-atleta-senha',
  templateUrl: './atleta-senha.component.html',
  styleUrls: ['./atleta-senha.component.scss']
})
export class AtletaSenhaComponent implements OnInit {

  
  isLoadingResults: boolean = false;
  atleta: Atleta = null;
  saveForm: FormGroup;
  
  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.getAtleta(this.route.snapshot.params.id);
  }

  getAtleta(id: number) {
    this.apiService.getAtleta(id)
      .subscribe((data: Atleta) => {
        this.atleta = data;

        this.saveForm = this.formBuilder.group({
          email : [data.email, Validators.required],
          email_confirmacao : [data.email, Validators.required],
          senha : ['', Validators.required],
          senha_confirmacao : ['', Validators.required],
        });

        this.isLoadingResults = false;
      }, (err: any) => {
        console.log(err);
        this.apiService.openSnackBarError(err.error.message);
        this.isLoadingResults = false;
      });
  }

  onFormSubmit() {
    this.isLoadingResults = true;
    this.apiService.atualizarSenha(this.saveForm.value)
      .subscribe((res: any) => {
          const id = res.id;
          this.isLoadingResults = false;
          this.apiService.openSnackBarSuccess('Senha atualizada com sucesso!');
          this.router.navigate(['/atleta-list']);
        }, (err: any) => {
          console.log(err);
          this.apiService.openSnackBarError(err.error.message);
          this.isLoadingResults = false;
        });
        
  }


}
