import { Component, OnInit } from '@angular/core';
import { Atleta } from 'src/app/model';
import { ApiService } from 'src/app/shared';

@Component({
  selector: 'app-atleta-list',
  templateUrl: './atleta-list.component.html',
  styleUrls: ['./atleta-list.component.scss']
})
export class AtletaListComponent implements OnInit {

  atletas: Array<Atleta> = [];
  displayedColumns: string[] = ['actions', 'email', 'ativo', 'dataCriacao'];
  isLoadingResults: boolean = false;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.loadList();
  }

  private loadList(): void {
    this.isLoadingResults = true;
    this.apiService.listAtletas()
    .subscribe((res: Array<Atleta>) => {
      this.atletas = res;      
      this.isLoadingResults = false;
    }, err => {
      console.log(err);
      this.isLoadingResults = false;
    });
  }

}